import { data } from './charts-data'

import { fetchData, createDropdown, drawGrapic } from './chart_helper'

import { $t } from './charts_locales'

const analytic__types = document.querySelector('.analytic__types')
const analytic__subtypes = document.querySelector('.analytic__subtypes')
const report__charts = document.querySelector('.report__charts')
// const report__count = document.querySelector('.report__count')

let types = []
let type = 0
let subtypes = []
let charts = []
let chartsData = []
let chartsGraph = []
let togglesActive = []

initTypes()
initSubtypes(type)

function initTypes() {
  type = 0
  types = data.map((h, hInd) => {
    const wrap = document.createElement('div')
    wrap.setAttribute('class', `title-4 analytic__type${hInd === 0 ? ' analytic__type--active' : ''}`)
    wrap.innerHTML = $t[h.type] || h.type
    wrap.addEventListener('click', () => {
      clickHead(hInd)
    })
    return wrap
  })
  analytic__types.append(...types)
}

function clickHead(hInd) {
  types[type].classList.remove('analytic__type--active')
  types[hInd].classList.add('analytic__type--active')
  type = hInd
  initSubtypes(hInd)
  document.querySelector('.report-total--active')?.classList.remove('report-total--active')
  document.querySelector('.report-total-' + hInd)?.classList.add('report-total--active')
  chartsData = []
  chartsGraph = []
}

function initSubtypes(hInd) {
  subtypes = data[hInd].subtypes.filter((e) => !e.hideMain).map((t, tInd) => {
    const toggle = document.createElement('button')
    toggle.setAttribute('class', `e-btn badge ${tInd === 0 ? 'badge--orange' : 'badge--blank'}`)
    toggle.textContent = $t[t.mainTitle || t.type] || t.mainTitle || t.type
    toggle.addEventListener('click', () => {
      setToggles(tInd, hInd)
    })
    return toggle
  })
  togglesActive = []
  setToggles(0, 0)
  toggleCounts()
  report__charts.innerHTML = ''
  analytic__subtypes.innerHTML = ''
  analytic__subtypes.append(...subtypes)
}

function initChart(t, tInd, isNoMain = false) {
  const chart = document.createElement('div')
  chart.setAttribute('class', 'badge--block badge--empty report')
  chart.style.order = tInd
  const chartHeader = document.createElement('div')
  chartHeader.setAttribute('class', 'report__header')
  const chartTitle = document.createElement('div')
  chartTitle.setAttribute('class', 'title-4 report__title')
  const chartText = document.createElement('span')
  chartText.innerHTML = $t[t.mainTitle] || $t[t.type] || t.type
  chartTitle.appendChild(chartText)
  if (t.info && $t[t.info]) {
    const chartInfo = document.createElement('div')
    chartInfo.setAttribute('class', 'report__info')
    const chartInfoBtn = document.createElement('button')
    chartInfoBtn.setAttribute('class', 'report__info-btn')
    chartInfo.appendChild(chartInfoBtn)
    const chartInfoBody = document.createElement('div')
    chartInfoBody.setAttribute('class', 'report__info-body')
    chartInfoBody.innerText = $t[t.info]
    chartInfo.appendChild(chartInfoBody)
    chartTitle.appendChild(chartInfo)
  }
  chartHeader.appendChild(chartTitle)
  if (isNoMain) {
    const chartFilters = document.createElement('div')
    chartFilters.setAttribute('class', 'report__filters')
    t.filters?.forEach((f) => {
      let elem
      if (f.type === 'dropdown') {
        elem = createDropdown(f, (option) => {
          if (chartsGraph[tInd] && chartsData[tInd]) {
            chartsGraph[tInd].update(t.updateFunc(chartsData[tInd], f, option))
            if (t.getFilterResults) {
              const filterResults = charts[tInd].querySelector('.report__filter-results')
              if (filterResults) filterResults.innerHTML = t.getFilterResults()
            }
          }
        })
        elem.classList.add('report__filter')
      }

      if (elem) chartFilters.appendChild(elem)
    })
    chartHeader.appendChild(chartFilters)
  }
  chart.appendChild(chartHeader)
  const chartBody = document.createElement('div')
  chartBody.setAttribute('id', t.type)
  chartBody.setAttribute('class', 'report__body bvi-no-styles')
  chart.appendChild(chartBody)
  if (t.getFilterResults) {
    const filterResults = document.createElement('div')
    filterResults.setAttribute('class', 'report__filter-results')
    filterResults.innerHTML = t.getFilterResults()
    chart.appendChild(filterResults)
  }
  charts[tInd] = chart
}

function toggleCounts() {
  // report__count.innerHTML = togglesActive.filter((e) => e).length
}

function setToggles(tInd, hInd) {
  togglesActive = togglesActive.map((e, ind) => {
    subtypes[ind].classList.remove('badge--orange')
    subtypes[ind].classList.add('badge--blank')
    toggleReport(ind, false, hInd)

    return false
  })

  togglesActive[tInd] = !togglesActive[tInd]

  if (togglesActive[tInd] && subtypes[tInd]) {
    subtypes[tInd].classList.remove('badge--blank')
    subtypes[tInd].classList.add('badge--orange')
    toggleReport(tInd, true, type)
  }
}

async function toggleReport(tInd, flag, typeInd) {
  if (flag) {
    const graphOpt = data[type].subtypes.filter((e) => !e.hideMain)[tInd]

    await loadData(graphOpt, tInd)
    if (type !== typeInd || !togglesActive[tInd]) return

    initChart(graphOpt, tInd)

    report__charts.appendChild(charts[tInd])

    loadGraphic(graphOpt, tInd)
  } else {
    if (charts[tInd] && charts[tInd].parentNode === report__charts) report__charts.removeChild(charts[tInd])
  }
}

async function loadData(graphOpt, tInd) {
  const fetchedData = await fetchData(graphOpt)
  chartsData[tInd] = graphOpt.normalize ? graphOpt.normalize(fetchedData) : fetchedData
}

async function loadGraphic(graphOpt, tInd) {
  const graph = drawGrapic(graphOpt, chartsData[tInd])
  chartsGraph[tInd] = graph
}
