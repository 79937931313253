const locales = {
  ru: {
    childrenDisabilities16: 'Дети с инвалидностью до 16 лет',
    disability_group: 'Группа инвалидности',
    pensioners: 'Пенсионеры',
    numberOfPensionBenefit: 'Кол-во получателей пенсии и пособии',
    largeFamilies: 'Многодетные',
    pwd: 'ЛСИ',
    childCareUp: 'По уходу за ребенком до 1/1.5 лет',
    populationStructureAge18: 'Структура населения РК',
    all: 'Всего',
    smz: 'СМЗ',
    srp: 'СРП',
    median: 'Медиана',
    base: 'Базовый сценарий',
    optimistic: 'Оптимистический сценарий',
    pesimistic: 'Пессимистический сценарий',
    'базовый': 'Базовый сценарий',
    'оптимистический': 'Оптимистический сценарий',
    'пессимистический': 'Пессимистический сценарий',
    ableToWorkOlder: 'Старше трудоспособного',
    ableToWork: 'Трудоспособного',
    ableToWorkYounger: 'Младше трудоспособного',
    salaryTng: 'Заработная плата (тенге)',
    populationStructure: 'Структура населения',
    populationStructures: {
      salaried: 'Наемные работники',
      children: 'Дети до 18 лет',
      pensioners: 'Пенсионеры',
      individual: 'Индивидуальные предприниматели',
      students: 'Студенты',
      disability: 'Лица с инвалидностью',
    },
    averageRegionSalary: 'Среднемесячная/Медианная заработная плата',
    regions: {
      megacities: 'Мегаполисы',
      west: 'Запад',
      north: 'Север',
      east: 'Восток',
      south: 'Юг',
      center: 'Центр',
    },
    forecastHiredWorkers: 'Прогноз общего спроса на наемных работников РК',
    employment: 'Занятость',
    employment1: 'Динамика трудоспособного населения',
    employment2: 'Трудоспособное население по возрасту и полу',
    employment3: 'Занятость населения по виду деятельности',
    employment4: 'Динамика молодежи',
    employment5: 'Занятые в ОКЭД',
    employment6: 'ТОП по НКЗ',
    employment7: 'Размерность предприятии',
    employment8: 'ЭТД раст. принят',
    employment10: 'Кол-во договоров с ОПВ/ЗП',
    employmentMain2: 'Трудоспособное население по возрасту и полу по РК за 2023 год',
    employmentMain3: 'Занятость населения по виду деятельности по РК за 2023 год',
    employment_info_1: '* количество физических лиц от 16 лет  до пенсионного возраста по данным ИС ГБД "Физические лица"',
    employment_info_2: '* количество физических лиц от 16 лет до пенсионного возраста по данным ИС ГБД "Физические лица',
    employment_info_3: '* Информация на оснований обязательных пенсионных взносов по данныз из ИС МТСЗН',
    employment_info_4: '* количество мужчин и женщин в возрасте 16-34 по данным ИС ГБД "Физические лица"',
    social_security: 'Социальное обеспечение',
    employment1Filters: (region, pol, age) => {
      return `<span>Регион: ${region}</span><span>Пол: ${pol}</span><span>Возраст: ${age}</span>`
    },
    employment2Filters: (region, employmentChatType) => {
      return `<span>Регион: ${region}</span><span>Показатель: ${employmentChatType}</span>`
    },
    social_security1: 'Динамика по получателям пенсии',
    social_security2: 'Динамика многодетным семьям',
    social_security3: 'Динамика по получателям пособии по уходу за ребенком до года/до полутора лет',
    social_security4: 'Динамика по получателям ГСП по случаю потери кормильца',
    social_security5: 'Сведения о получателях специальных социальных выплат из ОСНС',
    social_security6: 'Сведения о получателях пособия по случаю потери кормильца',
    social_security7: 'Социальные выплаты из ГФСС',
    social_security8: 'Сведения о получателях пособия по инвалидности',
    social_security_info_1: 'По данным ИС МТСЗН',
    social_security_info_2: 'По данным ИС МТСЗН',
    social_security_info_3: 'По данным ИС МТСЗН',
    social_security_info_4: 'По данным ИС МТСЗН',
    social_security_info_5: '* Информация на оснований назначении выплаты в ИС МТСЗН',
    social_security_info_6: '* Информация на оснований назначении  выплаты в ИС МТСЗН',
    social_security_info_7: '* Информация на оснований назначении выплаты в ИС МТСЗН',
    income: 'Доходы',
    income1: 'Среднемесячная заработная плата',
    income2: 'Количество людей по уровню ЗП',
    income3: 'СМЗ в разрезе ОКЭД и регионов',
    incomeMain3: 'СМЗ в разрезе ОКЭД по РК за 2024 год',
    income4: 'Градация по ЗП',
    income5: 'Аналитика по размерности предприятия',
    income6: 'Торнадо по возрасту и полу показатель средняя ЗП и отдельно медиана ',
    income7: 'Группы НКЗ',
    income8: 'СМЗ в разрезе по регионам',
    incomeMain8: 'СМЗ в разрезе по регионам',
    income_info_1: '*Информация на оснований обязательных пенсионных взносов по данным из ИС МТСЗН',
    income_info_2: '* Информация на оснований обязательных пенсионных взносов ',
    income_info_3: '*Информация на оснований обязательных пенсионных взносов по данным из ИС МТСЗН',
    income_info_4: '* Информация на оснований обязательных пенсионных взносов и Электронных трудовых договоров',
    income4Filters: (salary_type) => {
      return `<span>Уровень ЗП: ${salary_type}</span>`
    },
    income5Filters: (enterprises, index) => {
      return `<span>Показатель: ${index}</span><span>Предприятия: ${enterprises}</span>`
    },
    income6Filters: (year, regin, index) => {
      return `<span>Регион: ${regin}</span><span>Год: ${year}</span><span>Показатель: ${index}</span>`
    },
    projectionsDemographics: 'Прогнозы и демография',
    projectionsDemographics1: 'Прогноз населения Республики Казахстан',
    projectionsDemographics2: 'Прогноз по возрастным группам',
    projectionsDemographics3: 'Прогноз по полу и возрастным группам',
    projectionsDemographics4: 'Прогноз населения по типу местности',
    projectionsDemographics5: 'Прогноз численности населения по регионам',
    projectionsDemographics10: 'Прогноз возрастной структуры населения',
    projectionsDemographics11: 'Сценарный прогноз численности населения',
    projectionsDemographics12: 'Прогноз урбанизации',
    projectionsDemographics13: 'Прогноз естественного движения населения',
    projectionsDemographics_info_10: 'возраст младше трудоспособного: 0-15 лет; \n трудоспособный возраст: 16-60(62) лет; \n возраст старше трудоспособного: 61(63)+ лет.',
    projectionsDemographics_info_11: 'базовый сценарий – сценарий с постоянными демографическими параметрами рождаемости и смертности на уровне 2022 года.',
    projectionsDemographics_info_12: 'урбанизация – процесс концентрации населения в городах.',
    projectionsDemographics_info_13: 'естественное движение населения - изменение численности населения естественным путем, то есть в результате рождаемости и смертности.',
    projectionsDemographicsMainTitle10: 'Прогноз возрастной структуры населения РК',
    projectionsDemographicsMainTitle11: 'Сценарный прогноз численности населения РК',
    projectionsDemographicsMainTitle12: 'Прогноз урбанизации в РК на 2030 год',
    projectionsDemographicsMainTitle13: 'Прогноз естественного движения населения РК',
    projectionsDemographics1Filters: (pol, category) => {
      return `<span>Пол: ${pol}</span><span>Категория: ${category}</span>`
    },
    projectionsDemographics2Filters: (pol) => {
      return `<span>Пол: ${pol}</span>`
    },
    pol: 'Пол',
    age: 'Возраст',
    region: 'Регион',
    salary: 'ЗП',
    salary_type: 'Уровень ЗП',
    year: 'Год',
    year_low: 'год',
    employmentChatType: 'Показатель',
    age_18: 'до 18',
    age_25: 'с 18 до 25',
    age_30: 'с 25 до 35',
    age_40: 'с 35 до 45',
    age_50: 'с 45 до 55',
    age_60: 'старше 55',
    count_0_18: '0-18',
    count_19_25: '19-25',
    count_26_35: '26-35',
    count_36_55: '36-55',
    count_56_63: '56-63',
    count_63_old: '63 и старше',
    all_ages_count: 'Общий возраст',
    age_all: 'Общий',
    pol_man: 'Мужчины',
    pol_woman: 'Женщины',
    pol_both: 'Общий',
    male: 'Мужчины',
    female: 'Женщины',
    basic: 'Общий',
    do_mzp: 'до МЗП',
    mzp_do150000: 'от МЗП до 150 000',
    '800000_': 'от 800 000',
    'total_count': 'Общий',
    salaryRanges: {
      title1: 'Уровень ЗП',
      cnt_sicid_dO70: 'МЗП',
      cnt_sicid_Ot70_dO150: 'МЗП - 150 тыс. тг',
      cnt_sicid_Ot150_dO300: '150 - 300 тыс. тг',
      cnt_sicid_Ot300_dO500: '300 - 500 тыс. тг',
      cnt_sicid_Ot500_dO800: '500 - 800 тыс. тг',
      cnt_sicid_Ot800_dO1mln: '800 – и выше',
      cnt_sicid_Ot1mln: '0 – 1 млн',
    },
    working_age: 'трудоспособного возраста',
    '0-15': '0-15 лет',
    retirement_age: 'пенсионного возраста',
    township: 'Село',
    city: 'Город',
    projectionsDemographics6: 'Пенсионный возраст женщин',
    category: 'Категория',
    number_unique_people: 'Кол-во уникальных людей',
    do70000: 'до 70 000',
    ot70001_150000: 'от 70 001 до 150 000',
    ot150001_300000: 'от 150 001 до 300 000',
    ot300001_500000: 'от 300 001 до 500 000',
    ot500001_800000: 'от 500 001 до 800 000',
    ot800001_1000000: 'от 800 001 до 1 000 000',
    ot1000001_: 'от 1 000 001 и более',
    enterprises: 'Предприятия',
    index: 'Показатель',
    legal: 'ЮЛ',
    indinidual: 'ИП',
    enterprises_count: 'Кол-во предприятий',
    smz_za_2022: 'СМЗ за 2022 год',
    unique_enterprises_count: 'Кол-во уникальных людей',
    people_count: 'Кол-во людей',
    median_zp: 'Медиан ЗП',
    smz: 'СМЗ',
    total: 'Все',
    do_18: 'до 18',
    '18_25': 'с 18 до 25',
    '25_35': 'с 25 до 35',
    '35_45': 'с 35 до 45',
    '45_55': 'с 45 до 55',
    '55_bolee': 'старше 55',
    count: 'Кол-во',
    pessimistic_case: 'Пессимистичный сценарий',
    optimistic_case: 'Оптимистичный сценарий',
    basic_case: 'Базовый сценарий',
    youngs: 'Молодеж',
    count_people: 'Кол-во людей',
    middle_salary: 'Среднемесячная заработная плата',
    median_salary: 'Медианная заработная плата',
    smz_2022: 'СМЗ',
    unique_count_people: 'Кол-во людей',
    birth: 'Рождаемость',
    death: 'Смертность',
    sort_type: 'Сортировка',
    inc: 'По возрастанию',
    dec: 'По убыванию',
    people_counts: 'Количество людей',
    place: 'Тип местности',
    per_1000: 'тыс.',
    per_1mln: 'млн.',
    tg_1000: 'тыс.',
    tg_1mln: 'млн.',
    person_short: 'Чел.',
    scenario: 'Сценарий',
    middle_sum: 'СМЗ',
  },
  en: {
    childrenDisabilities16: 'Children with disabilities under 16 years of age',
    disability_group: 'Disability group',
    pensioners: 'Pensioners',
    numberOfPensionBenefit: 'Number of pension and benefit recipients',
    largeFamilies: 'Large families',
    pwd: 'PWD',
    childCareUp: 'Child care up to 1/1.5 years',
    populationStructureAge18: 'Population structure of the Republic of Kazakhstan',
    all: 'Total',
    smz: 'AMS',
    srp: 'APS',
    median: 'Median',
    base: 'Base scenario',
    optimistic: 'Optimistic scenario',
    pesimistic: 'Pessimistic scenario',
    'базовый': 'Base scenario',
    'оптимистический': 'Optimistic scenario',
    'пессимистический': 'Pessimistic scenario',
    ableToWorkOlder: 'Older than able-bodied',
    ableToWork: 'Able-bodied',
    ableToWorkYounger: 'Younger than able-bodied',
    salaryTng: 'Salary (tenge)',
    populationStructure: 'Population structure',
    populationStructures: {
      salaried: 'Wage-earners',
      children: 'Children under 18 years old',
      pensioners: 'Pensioners',
      individual: 'Individual entrepreneurs',
      students: 'Students',
      disability: 'Persons with disabilities',
    },
    averageRegionSalary: 'Average Monthly/Median salary',
    regions: {
      megacities: 'Megacities',
      west: 'West',
      north: 'North',
      east: 'East',
      south: 'South',
      center: 'Center',
    },
    forecastHiredWorkers: 'Forecast of the total demand for hired workers in the Republic of Kazakhstan',
    employment: 'Employment',
    employment1: 'Dynamics of the working-age population',
    employment2: 'Working-age population by age and gender',
    employment3: 'Employment by type of activity',
    employment4: 'Youth dynamics',
    employment5: 'Employed in OKED',
    employment6: 'TOP for NKZ',
    employment7: 'Enterprise size',
    employment8: 'ETD growth accepted',
    employment10: 'Number of contracts with OPV/ZP',
    employmentMain2: 'Working-age population by age and sex in the Republic of Kazakhstan for 2023',
    employmentMain3: 'Employment of the population by type of activity in the Republic of Kazakhstan for 2023',
    employment_info_1: '* number of individuals from 16 years to retirement age according to the data from the State Database Information System "Individuals"',
    employment_info_2: '* number of individuals from 16 years to retirement age according to the SDB IS “Individuals”',
    employment_info_3: '* Information on the basis of mandatory pension contributions according to data from the IS MLSPP',
    employment_info_4: '* number of men and women aged 16-34 according to the SDB IS “Individuals”',
    social_security: 'Social Security',
    employment1Filters: (region, pol, age) => {
      return `<span>Region: ${region}</span><span>Gender: ${pol}</span><span>Age: ${age}</span>`
    },
    employment2Filters: (region, employmentChatType) => {
      return `<span>Region: ${region}</span><span>Index: ${employmentChatType}</span>`
    },
    social_security1: 'Dynamics of pension recipients',
    social_security2: 'Dynamics of large families',
    social_security3: 'Dynamics of recipients of child care benefits up to one year old/up to one and a half years old',
    social_security4: 'Dynamics of SHG recipients in case of loss of a breadwinner',
    social_security5: 'Information about recipients of special social payments from the OSNS',
    social_security6: 'Information about recipients of survivor benefits',
    social_security7: 'Social payments from the State Social Insurance Fund',
    social_security8: 'Information about recipients of disability benefits',
    social_security_info_1: 'According to IS MLSZN',
    social_security_info_2: 'According to IS MLSZN',
    social_security_info_3: 'According to IS MLSZN',
    social_security_info_4: 'According to IS MLSZN',
    social_security_info_5: '* Information on the basis of payment assignment in the MLSPP IS',
    social_security_info_6: '* Information on the basis of payment assignment in the MLSPP IS',
    social_security_info_7: '* Information on the basis of payment assignment in the MLSPP IS',
    income: 'Income',
    income1: 'Average monthly salary',
    income2: 'Number of people by salary level',
    income3: 'SMZ in the context of OKED and regions',
    incomeMain3: 'SMZ in the context of OKED in the Republic of Kazakhstan for 2023',
    income4: 'Graduation by salary',
    income5: 'Analytics by enterprise size',
    income6: 'Tornado by age and gender indicator average salary and separately median',
    income7: 'NKZ Groups',
    income8: 'SMZ by region',
    incomeMain8: 'SMZ by region',
    income_info_1: '* Information on the basis of mandatory pension contributions according to data from the MLSPP IS',
    income_info_2: '* Information on the basis of mandatory pension contributions',
    income_info_3: '* Information on the basis of mandatory pension contributions according to data from the MLSPP IS',
    income_info_4: '* Information on the basis of mandatory pension contributions and Electronic labor contracts',
    income4Filters: (salary_type) => {
      return `<span>Salary level: ${salary_type}</span>`
    },
    income5Filters: (enterprises, index) => {
      return `<span>Index: ${index}</span><span>Enterprises: ${enterprises}</span>`
    },
    income6Filters: (year, regin, index) => {
      return `<span>Region: ${regin}</span><span>Year: ${year}</span><span>Index: ${index}</span>`
    },
    projectionsDemographics: 'Projections and demographics',
    projectionsDemographics1: 'Population forecast of the Republic of Kazakhstan',
    projectionsDemographics2: 'Forecast by age group',
    projectionsDemographics3: 'Forecast by gender and age groups',
    projectionsDemographics4: 'Population forecast by area type',
    projectionsDemographics5: 'Population forecast by region',
    projectionsDemographics10: 'Forecast of the age structure of the population',
    projectionsDemographics11: 'Scenario population forecast',
    projectionsDemographics12: 'Urbanization forecast',
    projectionsDemographics13: 'Vital forecast',
    projectionsDemographics_info_10: 'age below working age: 0-15 years; \n working age: 16-60(62) years; \n age over working age: 61(63)+ years.',
    projectionsDemographics_info_11: 'base scenario – a scenario with constant demographic parameters of fertility and mortality at the level of 2022.',
    projectionsDemographics_info_12: 'urbanization is the process of concentration of population in cities.',
    projectionsDemographics_info_13: 'natural population movement - a change in population size naturally, that is, as a result of fertility and mortality.',
    projectionsDemographicsMainTitle10: 'Forecast of the age structure of the population in the Republic of Kazakhstan',
    projectionsDemographicsMainTitle11: 'Scenario population forecast for the Republic of Kazakhstan',
    projectionsDemographicsMainTitle12: 'Forecast of urbanization in the Republic of Kazakhstan for 2030',
    projectionsDemographicsMainTitle13: 'Forecast of natural population movements in the Republic of Kazakhstan',
    projectionsDemographics1Filters: (pol, category) => {
      return `<span>Gender: ${pol}</span><span>Category: ${category}</span>`
    },
    projectionsDemographics2Filters: (pol) => {
      return `<span>Gender: ${pol}</span>`
    },
    pol: 'Gender',
    age: 'Age',
    region: 'Region',
    salary: 'Salary',
    salary_type: 'Salary level',
    year: 'Year',
    year_low: 'year',
    employmentChatType: 'Index',
    age_18: 'before 18',
    age_25: 'from 18 to 25',
    age_30: 'from 25 to 35',
    age_40: 'from 35 to 45',
    age_50: 'from 45 to 55',
    age_60: 'over 55',
    count_0_18: '0-18',
    count_19_25: '19-25',
    count_26_35: '26-35',
    count_36_55: '36-55',
    count_56_63: '56-63',
    count_63_old: '63 and older',
    all_ages_count: 'General age',
    age_all: 'General',
    pol_man: 'Men',
    pol_woman: 'Women',
    pol_both: 'General',
    male: 'Men',
    female: 'Women',
    basic: 'General',
    do_mzp: 'Up to minimum wage',
    mzp_do150000: 'From minimum wage to 150 000',
    '800000_': 'from 800 000',
    'total_count': 'General',
    salaryRanges: {
      title1: 'Salary level',
      cnt_sicid_dO70: 'MCI',
      cnt_sicid_Ot70_dO150: 'MCI - 150 K tg',
      cnt_sicid_Ot150_dO300: '150 - 300 K tg',
      cnt_sicid_Ot300_dO500: '300 - 500 K tg',
      cnt_sicid_Ot500_dO800: '500 - 800 K tg',
      cnt_sicid_Ot800_dO1mln: '800 – and above',
      cnt_sicid_Ot1mln: '0 – 1 million',
    },
    working_age: 'working age',
    '0-15': '0-15 years',
    retirement_age: 'retirement age',
    township: 'Village',
    city: 'City',
    projectionsDemographics6: 'Retirement age for women',
    category: 'Category',
    number_unique_people: 'Number of unique people',
    do70000: 'up to 70,000',
    ot70001_150000: 'from 70,001 to 150,000',
    ot150001_300000: 'from 150,001 to 300,000',
    ot300001_500000: 'from 300,001 to 500,000',
    ot500001_800000: 'from 500,001 to 800,000',
    ot800001_1000000: 'from 800,001 to 1,000,000',
    ot1000001_: 'from 1,000,001 and more',
    enterprises: 'Enterprises',
    index: 'Index',
    legal: 'Legal entity',
    indinidual: 'IP',
    enterprises_count: 'Number of enterprises',
    smz_za_2022: 'SMZ for 2022',
    unique_enterprises_count: 'Number of unique people',
    people_count: 'Number of people',
    median_zp: 'Median salary',
    smz: 'SMZ',
    total: 'All',
    do_18: 'before 18',
    '18_25': 'from 18 to 25',
    '25_35': 'from 25 to 35',
    '35_45': 'from 35 to 45',
    '45_55': 'from 45 to 55',
    '55_bolee': 'over 55',
    count: 'Qty',
    pessimistic_case: 'Pessimistic scenario',
    optimistic_case: 'Optimistic scenario',
    basic_case: 'Basic scenario',
    youngs: 'Youth',
    count_people: 'Number of people',
    middle_salary: 'Average monthly salary',
    median_salary: 'Median salary',
    smz_2022: 'SMZ',
    unique_count_people: 'Number of people',
    birth: 'Fertility',
    death: 'Mortality',
    sort_type: 'Sorting',
    inc: 'Ascending',
    dec: 'Descending',
    people_counts: 'Number of people',
    place: 'Terrain type',
    per_1000: 'thousand',
    per_1mln: 'million',
    tg_1000: 'thousand.',
    tg_1mln: 'million',
    person_short: 'Person',
    scenario: 'Scenario',
    middle_sum: 'SMZ',
  },
  kk: {
    childrenDisabilities16: '16 жасқа дейінгі мүмкіндігі шектеулі балалар',
    disability_group: 'Мүгедектік тобы',
    pensioners: 'Зейнеткерлер',
    numberOfPensionBenefit: 'Зейнетақы және жәрдемақы алушылардың саны',
    largeFamilies: 'Көп балалы отбасылар',
    pwd: 'МШТ',
    childCareUp: '1/1,5 жасқа дейінгі бала күтімі',
    populationStructureAge18: 'Қазақстан Республикасының халық құрылымы',
    all: 'Барлығы',
    smz: 'ОАЖ',
    srp: 'ОЗМ',
    median: 'Медиана',
    base: 'Негізгі сценарий',
    optimistic: 'Оптимистік сценарий',
    pesimistic: 'Пессимистік сценарий',
    'базовый': 'Негізгі сценарий',
    'оптимистический': 'Оптимистік сценарий',
    'пессимистический': 'Пессимистік сценарий',
    ableToWorkOlder: 'Еңбекке қабілетті жастан үлкен',
    ableToWork: 'Еңбекке қабілетті',
    ableToWorkYounger: 'Еңбекке қабілеттіден жас',
    salaryTng: 'Жалақы (теңге)',
    populationStructure: 'Популяция құрылымы',
    populationStructures: {
      salaried: 'Жалақы алатындар',
      children: '18 жасқа дейінгі балалар',
      pensioners: 'Зейнеткерлер',
      individual: 'Жеке кәсіпкерлер',
      students: 'Студенттер',
      disability: 'Мүмкіндігі шектеулі тұлғалар',
    },
    averageRegionSalary: 'Орташа айлық/Орташа жалақы',
    regions: {
      megacities: 'Мегаполистер',
      west: 'Батыс',
      north: 'Солтүстік',
      east: 'Шығыс',
      south: 'Оңтүстік',
      center: 'Орталық',
    },
    forecastHiredWorkers: 'Қазақстан Республикасындағы жалдамалы жұмысшыларға жалпы сұраныстың болжамы',
    employment: 'Жұмыспен қамту',
    employment1: 'Еңбекке қабілетті халықтың динамикасы',
    employment2: 'Жасы мен жынысы бойынша еңбекке қабілетті халық',
    employment3: 'Қызмет түрі бойынша жұмыспен қамту',
    employment4: 'Жастар динамикасы',
    employment5: 'OKED-де жұмыс істейді',
    employment6: 'NKZ үшін ТОП',
    employment7: 'Кәсіпорын мөлшері',
    employment8: 'ETD өсуі қабылданды',
    employment10: 'OPV/ZP келісім-шарттарының саны',
    employmentMain2: 'Қазақстан Республикасындағы 2023 жылға арналған жасы мен жынысы бойынша еңбекке қабілетті халық',
    employmentMain3: 'Қазақстан Республикасындағы 2023 жылға арналған қызмет түрлері бойынша халықты жұмыспен қамту',
    employment_info_1: '* «Жеке тұлғалар» АЖ МДБ деректері бойынша 16 жастан бастап зейнеткерлік жасқа дейінгі жеке тұлғалардың саны',
    employment_info_2: '* "Жеке тұлғалар" АЖ МДБ бойынша 16 жастан зейнеткерлік жасқа дейінгі жеке тұлғалардың саны',
    employment_info_3: '* Міндетті зейнетақы жарналары негізіндегі мәліметтер МӘМС АЖ деректері негізінде',
    employment_info_4: '* «Жеке тұлғалар» МДБ АЖ бойынша 16-34 жас аралығындағы ерлер мен әйелдер саны',
    social_security: 'Әлеуметтік қамсыздандыру',
    employment1Filters: (region, pol, age) => {
      return `<span>Аймақ: ${region}</span><span>Жынысы: ${pol}</span><span>Жасы: ${age}</span>`
    },
    employment2Filters: (region, employmentChatType) => {
      return `<span>Аймақ: ${region}</span><span>Индекс: ${employmentChatType}</span>`
    },
    social_security1: 'Зейнетақы алушылардың динамикасы',
    social_security2: 'Көп балалы отбасылардың динамикасы',
    social_security3: 'Бір жасқа дейінгі/бір жарым жасқа дейінгі бала күтімі бойынша жәрдемақы алушылардың динамикасы',
    social_security4: 'Асыраушысынан айырылу жағдайындағы ЭТЖ алушылардың динамикасы',
    social_security5: 'ОСНС-тен арнайы әлеуметтік төлемдерді алушылар туралы мәліметтер',
    social_security6: 'Асыраушысының жәрдемақысын алушылар туралы мәліметтер',
    social_security7: 'Мемлекеттік әлеуметтік сақтандыру қорынан төленетін әлеуметтік төлемдер',
    social_security8: 'Мүгедектік бойынша жәрдемақы алушылар туралы мәліметтер',
    social_security_info_1: 'ЕХӘҚМ АЖ сәйкес',
    social_security_info_2: 'ЕХӘҚМ АЖ сәйкес',
    social_security_info_3: 'ЕХӘҚМ АЖ сәйкес',
    social_security_info_4: 'ЕХӘҚМ АЖ сәйкес',
    social_security_info_5: '* ЕХӘҚМ АЖ-да төлем тағайындау негізінде мәліметтер',
    social_security_info_6: '* ЕХӘҚМ АЖ-да төлем тағайындау негізінде мәліметтер',
    social_security_info_7: '* ЕХӘҚМ АЖ-да төлем тағайындау негізінде мәліметтер',
    income: 'Кіріс',
    income1: 'Орташа айлық жалақы',
    income2: 'Жалақы деңгейі бойынша адамдар саны',
    income3: 'OKED және аймақтар контекстіндегі ОАЖ',
    incomeMain3: '2023 жылға арналған Қазақстан Республикасындағы ОКЕД контекстіндегі ОАЖ',
    income4: 'Жалақы бойынша оқуды бітіру',
    income5: 'Кәсіпорын өлшемі бойынша талдау',
    income6: 'Торнадо жас және гендерлік көрсеткіш бойынша орташа жалақы және бөлек медиана',
    income7: 'ҰҚЖ топтары',
    income8: 'Аймақ бойынша ОАЖ',
    incomeMain8: 'Аймақ бойынша ОАЖ',
    income_info_1: '* Міндетті зейнетақы жарналары негізінде МӘМС АЖ деректері бойынша мәліметтер',
    income_info_2: '* Міндетті зейнетақы жарналары туралы мәліметтер',
    income_info_3: '* Міндетті зейнетақы жарналары негізінде МӘМС АЖ деректері бойынша мәліметтер',
    income_info_4: '* Міндетті зейнетақы жарналары және Электрондық еңбек шарттары туралы мәліметтер',
    income4Filters: (salary_type) => {
      return `<span>Жалақы деңгейі: ${salary_type}</span>`
    },
    income5Filters: (enterprises, index) => {
      return `<span>Индекс: ${index}</span><span>Кәсіпорындар: ${enterprises}</span>`
    },
    income6Filters: (year, regin, index) => {
      return `<span>Аймақ: ${regin}</span><span>Жыл: ${year}</span><span>Индекс: ${index}</span>`
    },
    projectionsDemographics: 'Болжамдар және демографиялық көрсеткіштер',
    projectionsDemographics1: 'Қазақстан Республикасының халық санының болжамы',
    projectionsDemographics2: 'Жас тобы бойынша болжам',
    projectionsDemographics3: 'Жыныс және жас топтары бойынша болжам',
    projectionsDemographics4: 'Аудан түрлері бойынша халық санының болжамы',
    projectionsDemographics5: 'Аймақтар бойынша халық санының болжамы',
    projectionsDemographics10: 'Халықтың жас құрылымының болжамы',
    projectionsDemographics11: 'Сценарий популяциясының болжамы',
    projectionsDemographics12: 'Урбанизация болжамы',
    projectionsDemographics13: 'Өмірлік болжам',
    projectionsDemographics_info_10: 'еңбекке қабілетті жастан төмен жас: 0-15 жас; \n еңбекке қабілетті жас: 16-60(62) жас; \n еңбекке қабілетті жастан жоғары жас: 61(63)+ жас.',
    projectionsDemographics_info_11: 'базалық сценарий – 2022 жыл деңгейінде туудың және өлімнің тұрақты демографиялық параметрлері бар сценарий.',
    projectionsDemographics_info_12: 'урбанизация – халықтың қалаларға шоғырлану процесі.',
    projectionsDemographics_info_13: 'популяцияның табиғи қозғалысы – популяция санының табиғи жолмен өзгеруі, яғни туу және өлім-жітім нәтижесінде.',
    projectionsDemographicsMainTitle10: 'Қазақстан Республикасындағы халықтың жас құрылымының болжамы',
    projectionsDemographicsMainTitle11: 'Қазақстан Республикасы бойынша халық санының болжамының сценарийі',
    projectionsDemographicsMainTitle12: 'Қазақстан Республикасындағы урбанизацияның 2030 жылға арналған болжамы',
    projectionsDemographicsMainTitle13: 'Қазақстан Республикасындағы халықтың табиғи қозғалысының болжамы',
    projectionsDemographics1Filters: (pol, category) => {
      return `<span>Жынысы: ${pol}</span><span>Санат: ${category}</span>`
    },
    projectionsDemographics2Filters: (pol) => {
      return `<span>Жынысы: ${pol}</span>`
    },
    pol: 'Жынысы',
    age: 'Жасы',
    region: 'Аймақ',
    salary: 'Жалақы',
    salary_type: 'Жалақы деңгейі',
    year: 'Жыл',
    year_low: 'жыл',
    employmentChatType: 'Индекс',
    age_18: '18-ге дейін',
    age_25: '18-ден 25-ке дейін',
    age_30: '25-тен 35-ке дейін',
    age_40: '35-тен 45-ке дейін',
    age_50: '45-тен 55-ке дейін',
    age_60: '55-тен жоғары',
    count_0_18: '0-18',
    count_19_25: '19-25',
    count_26_35: '26-35',
    count_36_55: '36-55',
    count_56_63: '56-63',
    count_63_old: '3 және одан жоғары',
    all_ages_count: 'Жалпы жас',
    age_all: 'Жалпы',
    pol_man: 'Ерлер',
    pol_woman: 'Әйелдер',
    pol_both: 'Жалпы',
    male: 'Ерлер',
    female: 'Әйелдер',
    basic: 'Жалпы',
    do_mzp: 'ЕТЖ дейін',
    mzp_do150000: 'ЕТЖ-дан 150 мыңға дейін',
    '800000_': '800 000 бастап',
    'total_count': 'Жалпы',
    salaryRanges: {
      title1: 'Жалақы деңгейі',
      cnt_sicid_dO70: 'ЕТЖ',
      cnt_sicid_Ot70_dO150: 'ЕТЖ - 150 мың тг',
      cnt_sicid_Ot150_dO300: '150 - 300 мың тг',
      cnt_sicid_Ot300_dO500: '300 - 500 мың тг',
      cnt_sicid_Ot500_dO800: '500 - 800 мың тг',
      cnt_sicid_Ot800_dO1mln: '800 – және одан жоғары',
      cnt_sicid_Ot1mln: '0 – 1 млн',
    },
    working_age: 'еңбек жасы',
    '0-15': '0-15 жас',
    retirement_age: 'зейнеткерлік жас',
    township: 'Ауыл',
    city: 'Қала',
    projectionsDemographics6: 'Әйелдердің зейнеткерлік жасы',
    category: 'Санат',
    number_unique_people: 'Бірегей адамдар саны',
    do70000: '70 мыңға дейін',
    ot70001_150000: '70 001-ден 150 000-ға дейін',
    ot150001_300000: '150 001-ден 300 000-ға дейін',
    ot300001_500000: '300 001-ден 500 000-ға дейін',
    ot500001_800000: '500 001-ден 800 000-ға дейін',
    ot800001_1000000: '800 001-ден 1 000 000-ға дейін',
    ot1000001_: '1 000 001 және одан да көп',
    enterprises: 'Кәсіпорындар',
    index: 'Индекс',
    legal: 'Заңды тұлға',
    indinidual: 'Жеке кәсіпкер',
    enterprises_count: 'Кәсіпорындар саны',
    smz_za_2022: '2022 жылға арналған ОАЖ',
    unique_enterprises_count: 'Бірегей адамдар саны',
    people_count: 'Адамдар саны',
    median_zp: 'Орташа жалақы',
    smz: 'ОАЖ',
    total: 'Барлық',
    do_18: '18-ге дейін',
    '18_25': '18-ден 25-ке дейін',
    '25_35': '25-тен 35-ке дейін',
    '35_45': '35-тен 45-ке дейін',
    '45_55': '45-тен 55-ке дейін',
    '55_bolee': '55-тен жоғары',
    count: 'Саны',
    pessimistic_case: 'Пессимистік сценарий',
    optimistic_case: 'Оптимистік сценарий',
    basic_case: 'Негізгі сценарий',
    youngs: 'Молодеж',
    count_people: 'Адамдар саны',
    middle_salary: 'Орташа айлық жалақы',
    median_salary: 'Орташа жалақы',
    smz_2022: 'ОАЖ',
    unique_count_people: 'Адамдар саны',
    birth: 'Туу көрсеткіші',
    death: 'Өлім көрсеткіші',
    sort_type: 'Сұрыптау',
    inc: 'Көтерілу',
    dec: 'Төмендеу',
    people_counts: 'Адамдар саны',
    place: 'Жер бедерінің түрі',
    per_1000: 'мың',
    per_1mln: 'миллион',
    tg_1000: 'мың',
    tg_1mln: 'миллион',
    person_short: 'Адам',
    scenario: 'Сценарий',
    middle_sum: 'ОАЖ',
  },
}

function getLocale() {
  let locale = document.documentElement.getAttribute('lang') || 'ru'
  return locales[locale]
}

export const $t = getLocale()
