import Highcharts from 'highcharts';

import { $t } from './charts_locales';

export const data = [
  {
    type: 'employment',
    subtypes: [
      {
        type: 'employment1',
        api: '/integrate/employment/labor?type=employment_and_labor_employable',
        chart: 'column',
        info: 'employment_info_1',
        hideAnalitics: true,
        years: [],
        region: 'РК',
        pol: 3,
        filters: [
          {
            type: 'dropdown',
            label: 'region',
            options: [],
          },
          {
            type: 'dropdown',
            label: 'pol',
            options: [
              { id: 3, label: 'basic' },
              { id: 1, label: 'male' },
              { id: 2, label: 'female' },
            ],
          },
        ],
        normalize(data) {
          const obj = {};

          data.forEach((e) => {
            if (e.reg_ru === 'PK') return;
            if (!e.year) return;

            if (!obj[e.year]) obj[e.year] = {};

            if (!obj[e.year][e.reg_ru]) obj[e.year][e.reg_ru] = {};
            if (!obj[e.year]['РК']) obj[e.year]['РК'] = {};

            if (!obj[e.year][e.reg_ru][e.pol.id]) obj[e.year][e.reg_ru][e.pol.id] = e.count;
            if (!obj[e.year]['РК'][e.pol.id]) obj[e.year]['РК'][e.pol.id] = e.count;
            else obj[e.year]['РК'][e.pol.id] = obj[e.year]['РК'][e.pol.id] + e.count;
          });

          if (data.length) {
            this.years = Object.keys(obj).sort((a, b) => a - b);

            this.filters[0].options = Object.keys(obj[this.years[0]]).map((i) => {
              return {
                id: i,
                label: i,
              };
            })
          }

          return obj;
        },
        getSeries(data, years) {
          return [
            {
              name: 'Norway',
              color: '#F98500',
              data: years.map((y) => data[+y][this.region][this.pol]),
              stack: 'year',
            },
          ];
        },
        parseFunc(data) {
          return {
            credits: false,
            chart: {
              type: 'column',
            },
            title: {
              text: '',
            },
            xAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
              },
              categories: this.years,
            },
            yAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                formatter: (a) => {
                  if (a.value > 999999) return `${a.value / 1000000} ${$t['per_1mln']}`;
                  if (Math.round(a.value / 1000) > 0) return `${a.value / 1000} ${$t['per_1000']}`;
                  return `${a.value / 1000}`;
                },
              },
              allowDecimals: true,
              min: 0,
              title: {
                text: '',
              },
            },
            scrollbar: {
              enabled: true,
            },
            legend: {
              enabled: false,
            },
            tooltip: {
              style: {
                fontSize: '12px',
                fontWeight: 400,
              },
              valueDecimals: 3,
              useHTML: false,
              formatter: function () {
                return `<span><span> ${this.x.name} ${$t['year_low']}</span><br>${$t['person_short']
                  }: ${this.total.toLocaleString()}</span>`;
              },
            },
            plotOptions: {
              column: {
                stacking: 'normal',
              },
            },
            series: this.getSeries(data, this.years),
          };
        },
        updateFunc(data, filter, option) {
          this[filter.label] = option.id;

          return {
            series: this.getSeries(data, this.years),
          };
        },
      },
      {
        type: 'employment3',
        mainTitle: 'employmentMain3',
        api: '/integrate/employment/labor?type=employment_and_labor_by_type_activity',
        chart: 'bar',
        info: 'employment_info_3',
        year: 2023,
        region: 'РК',
        sort_type: 'inc',
        categories: [],
        filters: [
          {
            type: 'dropdown',
            label: 'year',
            options: [],
          },
          {
            type: 'dropdown',
            label: 'region',
            options: [],
          },
          {
            type: 'dropdown',
            label: 'sort_type',
            options: [
              { id: 'inc', label: 'inc' },
              { id: 'dec', label: 'dec' },
            ],
          },
        ],
        normalize(data) {
          const obj = {};
          const categories = [];

          data.forEach((e) => {
            if (!e.year) return;

            if (!obj[e.year]) obj[e.year] = {};

            if (!obj[e.year][e.region_id?.name || e.reg_ru]) obj[e.year][e.region_id?.name || e.reg_ru] = {};

            if (!categories.includes(e.name_oked)) categories.push(e.name_oked);

            if (!obj[e.year][e.region_id?.name || e.reg_ru][e.name_oked]) {
              obj[e.year][e.region_id?.name || e.reg_ru][e.name_oked] = e.count_people;
            }
          });

          if (data.length) {
            this.filters[0].options = Object.keys(obj)
              .sort((a, b) => b - a)
              .map((y) => ({
                id: y,
                label: y,
              }));
            this.year = this.filters[0].options[0].id;

            this.filters[1].options = Object.keys(obj[+this.year]).map((r) => {
              return {
                id: r,
                label: r,
              };
            }).sort((a, b) => obj[this.year][b.id]['ЗДРАВООХРАНЕНИЕ И СОЦИАЛЬНЫЕ УСЛУГИ'] - obj[this.year][a.id]['ЗДРАВООХРАНЕНИЕ И СОЦИАЛЬНЫЕ УСЛУГИ']);

            this.categories = categories.map((k) => {
              return {
                id: k,
                value: k,
              };
            });
          }

          return obj;
        },
        getData(data) {
          return this.categories
            .map((c) => data[this.year][this.region] ? data[this.year][this.region][c.id] : [0])
            .sort((a, b) => (a - b) * (this.sort_type === 'inc' ? 1 : -1));
        },
        getCategories(data) {
          const v = data[this.year][this.region];
          return this.categories
            .sort((a, b) => (v ? (v[a.id] - v[b.id]) : 0) * (this.sort_type === 'inc' ? 1 : -1))
            .map((c) => c.value);
        },
        parseFunc(data) {
          const seriesData = this.getData(data);

          return {
            credits: false,
            chart: {
              type: this.chart,
            },
            title: {
              text: '',
            },
            xAxis: {
              categories: this.getCategories(data),
              labels: {
                useHTML: true,
                style: {
                  width: '150px',
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                },
                step: 1,
                formatter: function () {
                  const value = typeof this.value === 'string' ? this.value : this.value.name
                  return (
                    `<div align="center" style="width:150px;text-transform:lowercase;overflow:hidden;" title="${value.toLowerCase()}"><span style="text-transform:uppercase;">${value[0]}</span>${value.slice(1)}</div>`
                  );
                },
              },
            },
            yAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                formatter: (a) => {
                  if (a.value > 999999) return `${a.value / 1000000} ${$t['per_1mln']}`;
                  if (Math.round(a.value / 1000) > 0) return `${a.value / 1000} ${$t['per_1000']}`;
                  return `${a.value / 1000}`;
                },
                rotation: -90,
              },
              scrollbar: {
                enabled: true,
              },
              accessibility: {
                rangeDescription: 'Range: 0 to 100000',
              },
              title: {
                text: '',
              },
            },
            scrollbar: {
              enabled: true,
            },
            legend: {
              enabled: false,
            },
            tooltip: {
              backgroundColor: null,
              borderWidth: 0,
              shadow: false,
              useHTML: true,
              formatter: function () {
                return (
                  '<div style="width: 200px; cursor: pointer; white-space: wrap; font-weight: 400; font-size: 12px; background-color: #fff;"><div title="' +
                  this.x +
                  '">' +
                  this.x +
                  ' - ' +
                  Math.abs(this.y) +
                  '</div><div/>'
                );
              },
              style: {
                fontSize: '12px',
              },
              valueSuffix: '',
            },
            series: [
              {
                name: '',
                color: '#FFA149',
                data: seriesData,
              },
            ],
            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {},
                },
              ],
            },
          };
        },
        updateFunc(data, filter, option) {
          this[filter.label] = option.id;
          const seriesData = this.getData(data);

          return {
            xAxis: {
              categories: this.getCategories(data),
              labels: {
                useHTML: true,
                style: {
                  width: '150px',
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                },
                step: 1,
                formatter: function () {
                  const value = typeof this.value === 'string' ? this.value : this.value.name
                  return (
                    `<div align="center" style="width:150px;text-transform:lowercase;overflow:hidden;" title="${value.toLowerCase()}"><span style="text-transform:uppercase;">${value[0]}</span>${value.slice(1)}</div>`
                  );
                },
              },
            },
            tooltip: {
              backgroundColor: null,
              borderWidth: 0,
              shadow: false,
              useHTML: true,
              formatter: function () {
                return (
                  '<div style="width: 200px; cursor: pointer; white-space: wrap; font-weight: 400; font-size: 12px; background-color: #fff;"><div title="' +
                  this.x +
                  '">' +
                  this.x +
                  ' - ' +
                  Math.abs(this.y) +
                  '</div><div/>'
                );
              },
              style: {
                fontSize: '12px',
              },
              valueSuffix: '',
            },
            series: [
              {
                name: '',
                data: seriesData,
              },
            ],
          };
        },
      },
      {
        type: 'employment4',
        api: '/integrate/employment/labor?type=employment_and_labor_young',
        chart: 'bar',
        info: 'employment_info_4',
        hideMain: true,
        year: '2023',
        pol: 'young_people',
        sort_type: 'inc',
        filters: [
          {
            type: 'dropdown',
            label: 'year',
            options: [],
          },
          {
            type: 'dropdown',
            label: 'pol',
            options: [
              { id: 'young_people', label: 'basic' },
              { id: 'male', label: 'male' },
              { id: 'female', label: 'female' },
            ],
          },
          {
            type: 'dropdown',
            label: 'sort_type',
            options: [
              { id: 'inc', label: 'inc' },
              { id: 'dec', label: 'dec' },
            ],
          },
        ],
        regions: [],
        normalize(data) {
          const obj = {};

          data.forEach((e) => {
            if (!e.year) return;

            if (!obj[e.year]) obj[e.year] = {};

            if (!obj[e.year][e.reg_ru])
              obj[e.year][e.reg_ru] = {
                male: e.men,
                female: e.women,
                young_people: e.young_people,
              };
          });

          if (data.length) {
            this.filters[0].options = Object.keys(obj)
              .sort((a, b) => b - a)
              .map((y) => ({
                id: y,
                label: y,
              }));

            this.year = this.filters[0].options[0].id;

            const yearData = obj[this.year];

            this.regions = Object.keys(yearData).filter((e) => e !== 'РК');
          }

          return obj;
        },
        getData(data) {
          return this.regions
            .map((c) => {
              try {
                return data[this.year][c][this.pol];
              } catch (e) {
                return [];
              }
            })
            .sort((a, b) => (a - b) * (this.sort_type === 'inc' ? 1 : -1));
        },
        getCategories(data) {
          const v = data[this.year];
          return this.regions.sort((a, b) => {
            const aV = v[a] ? v[a][this.pol] : 0;
            const bV = v[b] ? v[b][this.pol] : 0;
            return (aV - bV) * (this.sort_type === 'inc' ? 1 : -1);
          });
        },
        parseFunc(data) {
          const seriesData = this.getData(data);

          return {
            credits: false,
            chart: {
              type: this.chart,
            },
            title: {
              text: '',
            },
            xAxis: {
              categories: this.getCategories(data),
              labels: {
                useHTML: true,
                style: {
                  width: '150px',
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                },
                step: 1,
                formatter: function () {
                  const value = typeof this.value === 'string' ? this.value : this.value.name
                  return (
                    `<div align="center" style="width:150px;text-transform:capitalize;overflow:hidden;" title="${value.toLowerCase()}">${value}</div>`
                  );
                },
              },
            },
            yAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                formatter: (a) => {
                  if (a.value > 999999) return `${a.value / 1000000} ${$t['per_1mln']}`;
                  if (Math.round(a.value / 1000) > 0) return `${a.value / 1000} ${$t['per_1000']}`;
                  return `${a.value / 1000}`;
                },
                rotation: -90,
              },
              scrollbar: {
                enabled: true,
              },
              accessibility: {
                rangeDescription: 'Range: 0 to 100000',
              },
              title: {
                text: '',
              },
            },
            scrollbar: {
              enabled: true,
            },
            legend: {
              enabled: false,
            },
            tooltip: {
              backgroundColor: null,
              borderWidth: 0,
              shadow: false,
              useHTML: true,
              formatter: function () {
                return (
                  '<div style="width: 200px; cursor: pointer; white-space: wrap; font-weight: 400; font-size: 12px;"><div title="' +
                  this.x +
                  '">' +
                  this.x +
                  ' - ' +
                  Math.abs(this.y) +
                  '</div><div/>'
                );
              },
              style: {
                fontSize: '12px',
              },
              valueSuffix: '',
            },
            series: [
              {
                name: '',
                color: '#FFA149',
                data: seriesData,
              },
            ],
            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {},
                },
              ],
            },
          };
        },
        updateFunc(data, filter, option) {
          this[filter.label] = option.id;
          const seriesData = this.getData(data);

          return {
            xAxis: {
              categories: this.getCategories(data),
              labels: {
                useHTML: true,
                style: {
                  width: '150px',
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                },
                step: 1,
                formatter: function () {
                  const value = typeof this.value === 'string' ? this.value : this.value.name
                  return (
                    `<div align="center" style="width:150px;text-transform:capitalize;overflow:hidden;" title="${value.toLowerCase()}">${value}</div>`
                  );
                },
              },
            },
            series: [
              {
                name: '',
                data: seriesData,
              },
            ],
          };
        },
      },
      {
        type: 'employment4',
        api: '/integrate/employment/labor?type=employment_and_labor_young',
        chart: 'bar',
        info: 'employment_info_4',
        hideAnalitics: true,
        region: 'РК',
        filters: [
          {
            type: 'dropdown',
            label: 'region',
            options: [],
          },
        ],
        years: [],
        categories: [
          { id: 'male', color: '#FFA149' },
          { id: 'female', color: '#90BE6D' },
        ],
        normalize(data) {
          const obj = {};

          data.forEach((e) => {
            if (!e.year) return;

            if (!obj[e.year]) obj[e.year] = {};

            if (!obj[e.year][e.reg_ru])
              obj[e.year][e.reg_ru] = {
                male: e.men,
                female: e.women,
                young_people: e.young_people,
              };
          });

          if (data.length) {
            this.years = Object.keys(obj).sort();

            const yearData = obj[this.years[0]];

            this.filters[0].options = Object.keys(yearData).map((k) => {
              return {
                id: k,
                label: k,
              };
            });
          }

          return obj;
        },
        getSeries(data, years) {
          return this.categories.map((c) => {
            return {
              name: $t[c.id] || c.id,
              pol: $t[c.id],
              color: c.color,
              data: years.map((y) => +data[y][this.region][c.id]),
              stack: $t[c.id],
            };
          });
        },
        parseFunc(data) {
          return {
            credits: false,
            chart: {
              type: 'column',
            },
            title: {
              text: '',
            },
            xAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
              },
              categories: this.years,
            },
            yAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                formatter: (a) => {
                  if (a.value > 999999) return `${a.value / 1000000} ${$t['per_1mln']}`;
                  if (Math.round(a.value / 1000) > 0) return `${a.value / 1000} ${$t['per_1000']}`;
                  return `${a.value / 1000}`;
                },
              },
              allowDecimals: true,
              min: 0,
              title: {
                text: '',
              },
            },
            scrollbar: {
              enabled: true,
            },
            legend: {
              itemStyle: {
                font: '12px Trebuchet MS, Verdana, sans-serif',
              },
              enabled: true,
            },
            tooltip: {
              style: {
                fontSize: '14px',
                fontWeight: 400,
              },
              valueDecimals: 3,
              formatter: function () {
                return `${this.x}<br/>${this.series.name}: ${this.y}`;
              },
            },
            plotOptions: {
              column: {
                stacking: 'stream',
              },
            },
            series: this.getSeries(data, this.years),
          };
        },
        updateFunc(data, filter, option) {
          this[filter.label] = option.id;

          return {
            series: this.getSeries(data, this.years),
          };
        },
      },
      {
        type: 'employment1',
        api: '/integrate/employment/labor?type=employment_and_labor_employable',
        chart: 'bar',
        info: 'employment_info_1',
        hideMain: true,
        year: 2023,
        pol: 3,
        category: 1,
        sort_type: 'inc',
        filters: [
          {
            type: 'dropdown',
            label: 'year',
            options: [],
          },
          // {
          //   type: 'dropdown',
          //   label: 'category',
          //   options: [],
          // },
          {
            type: 'dropdown',
            label: 'pol',
            options: [
              { id: 3, label: 'basic' },
              { id: 1, label: 'male' },
              { id: 2, label: 'female' },
            ],
          },
          {
            type: 'dropdown',
            label: 'sort_type',
            options: [
              { id: 'inc', label: 'inc' },
              { id: 'dec', label: 'dec' },
            ],
          },
        ],
        regions: [],
        normalize(data) {
          const obj = {};

          data.forEach((e) => {
            if (!e.year) return;

            if (!obj[e.year]) obj[e.year] = {};

            if (!obj[e.year][e.reg_ru]) {
              obj[e.year][e.reg_ru] = {};
            }

            if (!obj[e.year][e.reg_ru]) {
              obj[e.year][e.reg_ru] = {};
            }

            if (!obj[e.year][e.reg_ru][e.pol.id]) {
              obj[e.year][e.reg_ru][e.pol.id] = e.count;
            }
          });

          if (data.length) {
            this.filters[0].options = Object.keys(obj)
              .sort((a, b) => b - a)
              .map((y) => ({
                id: y,
                label: y,
              }));

            this.year = this.filters[0].options[0].id;
            this.regions = Object.keys(obj[this.year]).filter((e) => e !== 'PK');

            // this.filters[1].options = Object.entries(obj[this.year][this.regions[0]]).map(([key, value]) => {
            //   return {
            //     id: key,
            //     label: value.name,
            //   };
            // });
            // this.category = this.filters[1].options[0].id;
          }

          return obj;
        },
        getData(data) {
          return this.regions
            .map((c) => {
              try {
                return data[this.year][c][this.pol];
              } catch (e) {
                return [];
              }
            })
            .sort((a, b) => (a - b) * (this.sort_type === 'inc' ? 1 : -1));
        },
        getCategories(data) {
          const year = data[this.year];
          return this.regions.sort((a, b) => {
            const aV = year[a] ? year[a][this.pol] : 0;
            const bV = year[b] ? year[b][this.pol] : 0;
            return (aV - bV) * (this.sort_type === 'inc' ? 1 : -1);
          });
        },
        parseFunc(data) {
          const seriesData = this.getData(data);
          return {
            credits: false,
            chart: {
              type: this.chart,
            },
            title: {
              text: '',
            },
            xAxis: {
              categories: this.getCategories(data),
              labels: {
                useHTML: true,
                style: {
                  width: '150px',
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                },
                step: 1,
                formatter: function () {
                  const value = typeof this.value === 'string' ? this.value : this.value.name
                  return (
                    `<div align="center" style="width:150px;text-transform:capitalize;overflow:hidden;">${value}</div>`
                  );
                },
              },
            },
            yAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                formatter: (a) => {
                  if (a.value > 999999) return `${a.value / 1000000} ${$t['per_1mln']}`;
                  if (Math.round(a.value / 1000) > 0) return `${a.value / 1000} ${$t['per_1000']}`;
                  return `${a.value / 1000}`;
                },
                rotation: -90,
              },
              scrollbar: {
                enabled: true,
              },
              accessibility: {
                rangeDescription: 'Range: 0 to 100000',
              },
              title: {
                text: '',
              },
            },
            scrollbar: {
              enabled: true,
            },
            legend: {
              enabled: false,
            },
            tooltip: {
              backgroundColor: null,
              borderWidth: 0,
              shadow: false,
              useHTML: true,
              formatter: function () {
                return (
                  '<div style="width: 200px; cursor: pointer; white-space: wrap; font-weight: 400; font-size: 12px;background-color: #fff;"><div title="' +
                  this.x +
                  '">' +
                  this.x +
                  ' - ' +
                  Math.abs(this.y) +
                  '</div><div/>'
                );
              },
              style: {
                fontSize: '12px',
              },
              valueSuffix: '',
            },
            series: [
              {
                name: '',
                color: '#FFA149',
                data: seriesData,
              },
            ],
            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {},
                },
              ],
            },
          };
        },
        updateFunc(data, filter, option) {
          this[filter.label] = option.id;
          const seriesData = this.getData(data);

          return {
            xAxis: {
              categories: this.getCategories(data),
              labels: {
                useHTML: true,
                style: {
                  width: '150px',
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                },
                step: 1,
                formatter: function () {
                  const value = typeof this.value === 'string' ? this.value : this.value.name
                  return (
                    `<div align="center" style="width:150px;text-transform:capitalize;overflow:hidden;">${value}</div>`
                  );
                },
              },
            },
            tooltip: {
              backgroundColor: null,
              borderWidth: 0,
              shadow: false,
              useHTML: true,
              formatter: function () {
                return (
                  '<div style="width: 200px; cursor: pointer; white-space: wrap; font-weight: 400; font-size: 12px;background-color: #fff;"><div title="' +
                  this.x +
                  '">' +
                  this.x +
                  ' - ' +
                  Math.abs(this.y) +
                  '</div><div/>'
                );
              },
              style: {
                fontSize: '12px',
              },
              valueSuffix: '',
            },
            series: [
              {
                name: '',
                data: seriesData,
              },
            ],
          };
        },
      },
      {
        type: 'employment2',
        mainTitle: 'employmentMain2',
        class: 'col-12 col-lg-6',
        api: '/integrate/employment/labor?type=employment_and_labor_by_gender_and_age',
        chart: 'bar',
        info: 'employment_info_2',
        year: 2023,
        region: 'РК',
        index: 'people_count',
        filters: [
          {
            type: 'dropdown',
            label: 'year',
            options: [],
          },
          {
            type: 'dropdown',
            label: 'region',
            options: [],
          },
        ],
        ages: [],
        normalize(data) {
          const obj = {};

          data.forEach((e) => {
            if (!e.year) return;

            if (!obj[e.year]) obj[e.year] = {};
            if (!obj[e.year][e.reg_ru]) obj[e.year][e.reg_ru] = {};
            if (!obj[e.year][e.reg_ru][e.pol]) obj[e.year][e.reg_ru][e.pol] = {};
            if (!obj[e.year][e.reg_ru][e.pol][e.age])
              obj[e.year][e.reg_ru][e.pol][e.age] = {
                people_count: e.count_people,
              };
          });

          if (data.length) {
            this.filters[0].options = Object.keys(obj)
              .sort((a, b) => b - a)
              .map((e) => ({
                id: e,
                label: e,
              }));
            this.year = this.filters[0].options[0].id;

            this.filters[1].options = Object.keys(obj[this.year]).map((i) => {
              return {
                id: i,
                label: i,
              };
            });
            this.ages = Object.keys(obj[this.year][this.region]['1']).sort();
          }
          return obj;
        },
        getMale(data) {
          return this.ages.map((age) => {
            const d = data[this.year][this.region]['1'][age];
            if (d) return d[this.index] * -1;
            return 0;
          });
        },
        getFeMale(data) {
          return this.ages.map((age) => {
            const d = data[this.year][this.region]['2'][age];
            if (d) return d[this.index];
            return 0;
          });
        },
        parseFunc(data) {
          return {
            credits: false,
            chart: {
              type: this.chart,
              height: 800,
            },
            title: {
              text: '',
            },
            legend: {
              enabled: true,
              itemStyle: {
                font: '12px Trebuchet MS, Verdana, sans-serif',
              },
            },
            scrollbar: {
              enabled: true,
            },
            xAxis: [
              {
                reversed: false,
                categories: this.ages,
                opposite: true,
                labels: {
                  step: 1,
                  style: {
                    color: '#626A77',
                    fontWeight: '400',
                    fontSize: '12px',
                  },
                },
                accessibility: {
                  description: $t['pol_man'],
                },
              },
              {
                reversed: false,
                categories: this.ages,
                linkedTo: 0,
                labels: {
                  step: 1,
                  style: {
                    color: '#626A77',
                    fontWeight: '400',
                    fontSize: '12px',
                  },
                },
                accessibility: {
                  description: $t['pol_woman'],
                },
              },
            ],
            yAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                formatter: (a) => {
                  if (Math.abs(a.value) > 999999) return `${Math.abs(a.value) / 1000000} ${$t['per_1mln']}`;
                  if (Math.round(Math.abs(a.value) / 1000) > 0) return `${Math.abs(a.value) / 1000} ${$t['per_1000']}`;
                  return `${Math.abs(a.value) / 1000}`;
                },
                rotation: -90,
              },
              scrollbar: {
                enabled: true,
              },
              accessibility: {
                rangeDescription: 'Range: 0 to 100000',
              },
              title: {
                text: '',
              },
            },
            tooltip: {
              style: {
                fontSize: '12px',
                fontWeight: 400,
              },
              valueDecimals: 3,
              useHTML: false,
              formatter: function () {
                return `<span><span> ${this.x.name} ${$t['year_low']}</span><br>${$t['person_short']}: ${Math.abs(
                  this.total
                ).toLocaleString()}</span>`;
              },
            },
            plotOptions: {
              series: {
                stacking: 'normal',
                borderRadius: '50%',
              },
            },
            series: [
              {
                name: $t['pol_man'],
                color: '#FFA149',
                data: this.getMale(data),
              },
              {
                name: $t['pol_woman'],
                color: '#90BE6D',
                data: this.getFeMale(data),
              },
            ],
          };
        },
        updateFunc(data, filter, option) {
          this[filter.label] = option.id;

          return {
            series: [
              {
                name: $t['pol_man'],
                data: this.getMale(data),
              },
              {
                name: $t['pol_woman'],
                data: this.getFeMale(data),
              },
            ],
          };
        },
      },
    ],
  },
  {
    type: 'income',
    subtypes: [
      {
        type: 'income1',
        api: '/integrate?method=1',
        chart: 'line',
        info: 'income_info_1',
        region: 'PK',
        age: 0,
        pol: 3,
        filters: [
          {
            type: 'dropdown',
            label: 'region',
            options: [],
          },
          {
            type: 'dropdown',
            label: 'pol',
            options: [
              { id: 3, label: 'pol_both' },
              { id: 1, label: 'pol_man' },
              { id: 2, label: 'pol_woman' },
            ],
          },
          {
            type: 'dropdown',
            label: 'age',
            options: [
              { id: 0, label: 'age_all' },
              { id: 'do18', label: 'age_18' },
              { id: '18_25', label: 'age_25' },
              { id: '25_35', label: 'age_30' },
              { id: '35_45', label: 'age_40' },
              { id: '45_55', label: 'age_50' },
              { id: '55B', label: 'age_60' },
            ],
          },
        ],
        years: [],
        getSMS(data) {
          return Object.values(data).map((year) => {
            if (!year[this.region] || !year[this.region][this.pol]) return 0;

            const o = year[this.region][this.pol];

            if (this.age === 0) return o['smz'];
            else {
              return o[`smz_${this.age}`] || 0;
            }
          });
        },
        getMedian(data) {
          return Object.values(data).map((year) => {
            if (!year[this.region] || !year[this.region][this.pol]) return 0;

            const o = year[this.region][this.pol];

            if (this.age === 0) return o['med'] || 0;
            else {
              return o[`med_${this.age}`] || 0;
            }
          });
        },
        normalize(data) {
          const obj = {};

          data.forEach((e) => {
            if (!e.god) return;

            if (!obj[e.god]) obj[e.god] = {};

            if (!obj[e.god][e.reg_ru]) {
              obj[e.god][e.reg_ru] = {
                name: e.reg_ru,
              };
            }

            if (!obj[e.god][e.reg_ru][e.tip_pol]) {
              obj[e.god][e.reg_ru][e.tip_pol] = e;
            }
          });

          if (data.length) {
            this.years = Object.keys(obj).sort((a, b) => a - b)

            const lastYear = this.years[this.years.length - 1]

            this.filters[0].options = Object.entries(obj[lastYear])
              .map(([k, v]) => {
                return {
                  id: k,
                  label: v.name,
                };
              });
          }

          return obj;
        },
        parseFunc(data) {
          return {
            credits: false,
            chart: {
              type: this.chart,
            },
            title: {
              text: '',
            },
            yAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
              },
              title: {
                text: $t['salaryTng'],
              },
            },
            tooltip: {
              valueSuffix: '',
              style: {
                fontSize: '12px',
              },
            },
            xAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
              },
              categories: Object.keys(data),
            },
            scrollbar: {
              enabled: true,
            },
            legend: {
              layout: 'vertical',
              align: 'right',
              verticalAlign: 'middle',
            },
            plotOptions: {
              line: {
                pointPadding: 0.2,
                borderWidth: 0,
                groupPadding: 0.35,
                dataLabels: {
                  enabled: true,
                  position: 'right',
                  style: {
                    color: '#626A77',
                    fontWeight: '400',
                    fontSize: '12px',
                    // writingMode: 'vertical-rl',
                    // textOrientation: 'revert',
                  },
                },
              },
            },
            series: [
              {
                name: $t['smz'],
                color: '#FFA149',
                data: this.getSMS(data),
              },
              {
                name: $t['median'],
                color: '#6893FF',
                data: this.getMedian(data),
              },
            ],
            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {
                    legend: {
                      layout: 'horizontal',
                      align: 'center',
                      verticalAlign: 'bottom',
                    },
                  },
                },
              ],
            },
          };
        },
        updateFunc(data, filter, option) {
          this[filter.label] = option.id;
          return {
            series: [
              {
                name: $t['smz'],
                data: this.getSMS(data),
              },
              {
                name: $t['median'],
                data: this.getMedian(data),
              },
            ],
          };
        },
      },
      {
        type: 'income3',
        mainTitle: 'incomeMain3',
        api: '/integrate/revenue?type=oked_data',
        chart: 'bar',
        info: 'income_info_2',
        region: 11,
        year: '2023',
        employmentChatType: 'smz',
        categories: {},
        sort_type: 'inc',
        filters: [
          {
            type: 'dropdown',
            label: 'year',
            options: [],
          },
          {
            type: 'dropdown',
            label: 'region',
            options: [],
          },
          {
            type: 'dropdown',
            label: 'employmentChatType',
            options: [
              { id: 'smz', label: 'salary' },
              { id: 'cnt_sicid', label: 'people_counts' },
            ],
          },
          {
            type: 'dropdown',
            label: 'sort_type',
            options: [
              { id: 'inc', label: 'inc' },
              { id: 'dec', label: 'dec' },
            ],
          },
        ],
        normalize(data) {
          const obj = {};

          data.forEach((e) => {
            if (!e.god) return;

            if (!obj[e.god]) obj[e.god] = {};

            if (!obj[e.god][e.regin]) {
              obj[e.god][e.regin] = {
                name: e.reg_ru,
              };
            }

            if (!this.categories[e.vcode_oked]) this.categories[e.vcode_oked] = e.vname_oked;

            if (!obj[e.god][e.regin][e.vcode_oked]) {
              obj[e.god][e.regin][e.vcode_oked] = e;
            }
          });

          if (data.length) {
            this.filters[0].options = Object.keys(obj).sort((a, b) => b - a).map((y) => ({
              id: y,
              label: y,
            }))

            this.year = this.filters[0].options[0].id;

            this.filters[1].options = Object.entries(obj[this.year])
              .map(([k, v]) => {
                return {
                  id: k,
                  label: v.name,
                };
              })
              .sort((a, b) => b.id - a.id);

            this.region = this.filters[1].options[0].id;
          }

          return obj;
        },
        getSalary(data) {
          const v = data[this.year] && data[this.year][this.region] ? data[this.year][this.region] : null;
          return Object.keys(this.categories)
            .map((e) => v && v[e] && v[e][this.employmentChatType] ? v[e][this.employmentChatType] : 0)
            .sort((a, b) => (a - b) * (this.sort_type === 'inc' ? 1 : -1));
        },
        getCategories(data) {
          const v = data[this.year][this.region];
          const categories = Object.keys(this.categories)
            .sort((a, b) => {
              return (v ? ((v[a] ? v[a][this.employmentChatType] : 0) - (v[b] ? v[b][this.employmentChatType] : 0)) : 0) * (this.sort_type === 'inc' ? 1 : -1);
            })
            .map((k) => this.categories[k]);

          return categories;
        },
        parseFunc(data) {
          const seriesData = this.getSalary(data);

          return {
            credits: false,
            chart: {
              type: this.chart,
            },
            title: {
              text: '',
            },
            xAxis: {
              categories: this.getCategories(data),
              labels: {
                useHTML: true,
                style: {
                  width: '250px',
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                step: 1,
                formatter: function () {
                  const value = typeof this.value === 'string' ? this.value : this.value.name
                  return (
                    `<div align="center" style="width:150px;text-transform:lowercase;overflow:hidden;" title="${value.toLowerCase()}"><span style="text-transform:uppercase;">${value[0]}</span>${value.slice(1)}</div>`
                  );
                },
              },
            },
            yAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
              },
              min: 0,
              title: {
                text: '',
              },
            },
            scrollbar: {
              enabled: true,
            },
            legend: {
              enabled: false,
            },
            tooltip: {
              backgroundColor: null,
              borderWidth: 0,
              shadow: false,
              useHTML: true,
              formatter: function () {
                return (
                  '<div style="width: 200px; cursor: pointer; white-space: wrap; font-weight: 400; font-size: 12px; background-color: #fff;"><div title="' +
                  this.x +
                  '">' +
                  this.x +
                  ' - ' +
                  Math.abs(this.y) +
                  '</div><div/>'
                );
              },
              style: {
                fontSize: '12px',
              },
              valueSuffix: '',
            },
            series: [
              {
                name: '',
                color: '#FFA149',
                width: 250,
                labels: {
                  style: {
                    wordBreak: 'break-all',
                    textOverflow: 'allow',
                    width: 350,
                  },
                },
                data: seriesData,
              },
            ],
            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {},
                },
              ],
            },
          };
        },
        updateFunc(data, filter, option) {
          this[filter.label] = option.id;
          const seriesData = this.getSalary(data);

          return {
            xAxis: {
              categories: this.getCategories(data),
              labels: {
                useHTML: true,
                style: {
                  width: '250px',
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                step: 1,
                formatter: function () {
                  const value = typeof this.value === 'string' ? this.value : this.value.name
                  return (
                    `<div align="center" style="width:150px;text-transform:lowercase;overflow:hidden;" title="${value.toLowerCase()}"><span style="text-transform:uppercase;">${value[0]}</span>${value.slice(1)}</div>`
                  );
                },
              },
            },
            tooltip: {
              backgroundColor: null,
              borderWidth: 0,
              shadow: false,
              useHTML: true,
              formatter: function () {
                return (
                  '<div style="width: 200px; cursor: pointer; white-space: wrap; font-weight: 400; font-size: 12px; background-color: #fff;"><div title="' +
                  this.x +
                  '">' +
                  this.x +
                  ' - ' +
                  Math.abs(this.y) +
                  '</div><div/>'
                );
              },
              style: {
                fontSize: '12px',
              },
              valueSuffix: '',
            },
            series: [
              {
                name: '',
                data: seriesData,
              },
            ],
          };
        },
      },
      {
        type: 'income8',
        mainTitle: 'incomeMain8',
        api: '/integrate?method=1',
        chart: 'bar',
        info: 'income_info_2',
        year: '2023',
        employmentChatType: 'smz',
        sort_type: 'inc',
        filters: [
          {
            type: 'dropdown',
            label: 'year',
            options: [],
          },
          {
            type: 'dropdown',
            label: 'sort_type',
            options: [
              { id: 'inc', label: 'inc' },
              { id: 'dec', label: 'dec' },
            ],
          },
        ],
        categories: {},
        regions: [],
        normalize(data) {
          const obj = {};

          data.forEach((e) => {
            if (!e.god) return;

            if(e.tip_pol != 3) return;

            if (!obj[e.god]) obj[e.god] = {};

            if (!obj[e.god][e.regid]) {
              obj[e.god][e.regid] = {
                name: e.reg_ru,
              };
            }

            if (!this.categories[e.vcode_oked]) this.categories[e.vcode_oked] = e.vname_oked;

            if (!obj[e.god][e.regid][e.vcode_oked]) {
              obj[e.god][e.regid][e.vcode_oked] = e;
            }
          });

          if (data.length) {
            this.filters[0].options = Object.keys(obj).sort((a, b) => b - a).map((y) => ({
              id: y,
              label: y,
            }))

            this.year = this.filters[0].options[0].id;

            this.regions = Object.entries(obj[this.year])
              .map(([k, v]) => {
                return {
                  id: k,
                  label: v.name,
                };
              }).filter((e) => e.id !== '99');
          }

          return obj;
        },
        getSalary(data) {
          if (!data[this.year]) return [];
          const categories = Object.keys(this.categories)
          const yearD = data[this.year];
          return this.regions
            .map((r) => categories.reduce((t, e) => {
              t += yearD[r.id] && yearD[r.id][e] ? yearD[r.id][e][this.employmentChatType] : 0
              return t
            }, 0)).map((r) => Math.round(r / categories.length))
            .sort((a, b) => (a - b) * (this.sort_type === 'inc' ? 1 : -1));
        },
        getCategories(data) {
          const categories = Object.keys(this.categories)
          const yearD = data[this.year]
          return this.regions.sort((a, b) => {
            const f = categories.reduce((t, e) => {
              t += yearD[a.id] && yearD[a.id][e] ? yearD[a.id][e][this.employmentChatType] : 0
              return t
            }, 0) / categories.length
            const s = categories.reduce((t, e) => {
              t += yearD[b.id] && yearD[b.id][e] ? yearD[b.id][e][this.employmentChatType] : 0
              return t
            }, 0) / categories.length
            return (f - s) * (this.sort_type === 'inc' ? 1 : -1);
          })
            .map((region) => region.label);
        },
        parseFunc(data) {
          const seriesData = this.getSalary(data);

          return {
            credits: false,
            chart: {
              type: this.chart,
            },
            title: {
              text: '',
            },
            xAxis: {
              categories: this.getCategories(data),
              labels: {
                useHTML: true,
                style: {
                  width: '250px',
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                step: 1,
                formatter: function () {
                  const value = typeof this.value === 'string' ? this.value : this.value.name
                  return (
                    `<div align="center" style="width:150px;overflow:hidden;" title="${value}">${value}</div>`
                  );
                },
              },
            },
            yAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
              },
              min: 0,
              title: {
                text: '',
              },
            },
            scrollbar: {
              enabled: true,
            },
            legend: {
              enabled: false,
            },
            tooltip: {
              backgroundColor: null,
              borderWidth: 0,
              shadow: false,
              useHTML: true,
              formatter: function () {
                return (
                  '<div style="width: 200px; cursor: pointer; white-space: wrap; font-weight: 400; font-size: 12px; background-color: #fff;"><div title="' +
                  this.x +
                  '">' +
                  this.x +
                  ' - ' +
                  Math.abs(this.y) +
                  '</div><div/>'
                );
              },
              style: {
                fontSize: '12px',
              },
              valueSuffix: '',
            },
            series: [
              {
                name: '',
                color: '#FFA149',
                width: 250,
                labels: {
                  style: {
                    wordBreak: 'break-all',
                    textOverflow: 'allow',
                    width: 350,
                  },
                },
                data: seriesData,
              },
            ],
            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {},
                },
              ],
            },
          };
        },
        updateFunc(data, filter, option) {
          this[filter.label] = option.id;
          const seriesData = this.getSalary(data);

          return {
            xAxis: {
              categories: this.getCategories(data),
              labels: {
                useHTML: true,
                style: {
                  width: '250px',
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                step: 1,
                formatter: function () {
                  const value = typeof this.value === 'string' ? this.value : this.value.name
                  return (
                    `<div align="center" style="width:150px;overflow:hidden;" title="${value}">${value}</div>`
                  );
                },
              },
            },
            tooltip: {
              backgroundColor: null,
              borderWidth: 0,
              shadow: false,
              useHTML: true,
              formatter: function () {
                return (
                  '<div style="width: 200px; cursor: pointer; white-space: wrap; font-weight: 400; font-size: 12px; background-color: #fff;"><div title="' +
                  this.x +
                  '">' +
                  this.x +
                  ' - ' +
                  Math.abs(this.y) +
                  '</div><div/>'
                );
              },
              style: {
                fontSize: '12px',
              },
              valueSuffix: '',
            },
            series: [
              {
                name: '',
                data: seriesData,
              },
            ],
          };
        },
      },
      // {
      //   type: 'income2',
      //   api: '/integrate?method=3',
      //   chart: 'column',
      //   info: 'income_info_3',
      //   region: 71,
      //   year: 0,
      //   pol: 3,
      //   categories: [
      //     'cnt_sicid_dO70',
      //     'cnt_sicid_Ot70_dO150',
      //     'cnt_sicid_Ot150_dO300',
      //     'cnt_sicid_Ot300_dO500',
      //     'cnt_sicid_Ot500_dO800',
      //     'cnt_sicid_Ot800_dO1mln',
      //     // 'cnt_sicid_Ot1mln',
      //   ],
      //   filters: [
      //     {
      //       type: 'dropdown',
      //       label: 'region',
      //       options: [],
      //     },
      //     {
      //       type: 'dropdown',
      //       label: 'pol',
      //       options: [
      //         { id: 3, label: 'pol_both' },
      //         { id: 1, label: 'pol_man' },
      //         { id: 2, label: 'pol_woman' },
      //       ],
      //     },
      //   ],
      //   getSalaryByAge(data) {
      //     const o = data[this.year][this.region][this.pol];
      //     return this.categories?.map((c) => {
      //       if (c === 'cnt_sicid_Ot800_dO1mln') return o[c] + o['cnt_sicid_Ot1mln']
      //       else return o[c]
      //     }) || [];
      //   },
      //   normalize(data) {
      //     const obj = {};

      //     data.forEach((e) => {
      //       if (!e.god) return;

      //       if (!obj[e.god]) obj[e.god] = {};

      //       if (!obj[e.god][e.regin]) {
      //         obj[e.god][e.regin] = {
      //           name: e.reg_ru,
      //         };
      //       }

      //       if (!obj[e.god][e.regin][e.tip_pol]) {
      //         obj[e.god][e.regin][e.tip_pol] = e;
      //       }
      //     });

      //     if (data.length) {
      //       this.filters[0].options = Object.entries(Object.values(obj)[0])
      //         .map(([k, v]) => {
      //           return {
      //             id: k,
      //             label: v.name,
      //           };
      //         })
      //         .sort((a, b) => b.id - a.id);

      //       this.region = this.filters[0].options[0].id;
      //       const years = Object.keys(obj).sort((a, b) => b - a);
      //       this.year = years[years.length - 1];
      //     }

      //     return obj;
      //   },
      //   parseFunc(data) {
      //     return {
      //       credits: false,
      //       chart: {
      //         type: this.chart,
      //         scrollablePlotArea: {
      //           minWidth: 300,
      //         },
      //       },
      //       title: {
      //         text: '',
      //       },
      //       legend: {
      //         enabled: false,
      //       },
      //       scrollbar: {
      //         enabled: true,
      //       },
      //       xAxis: {
      //         labels: {
      //           useHTML: true,
      //           style: {
      //             width: '50px',
      //             color: '#626A77',
      //             fontWeight: '400',
      //             fontSize: '11px',
      //             whiteSpace: "normal",
      //           },
      //           allowOverlap: true,
      //           rotation: 0,
      //           formatter: function () {
      //             const value = typeof this.value === 'string' ? this.value : this.value.name
      //             return (
      //               `<div align="center" style="width:50px;overflow:hidden;text-align:center;">${value}</div>`
      //             );
      //           },
      //         },
      //         categories: this.categories.map((c) => $t.salaryRanges[c] || c),
      //         crosshair: true,
      //       },
      //       yAxis: {
      //         labels: {
      //           style: {
      //             color: '#626A77',
      //             fontWeight: '400',
      //             fontSize: '12px',
      //           },
      //           formatter: (a) => {
      //             return `${(Math.abs(a.value) / 1000000).toFixed(2)} М`;
      //           },
      //         },
      //         min: 0,
      //         title: {
      //           text: '',
      //         },
      //       },
      //       tooltip: {
      //         valueSuffix: '',
      //         style: {
      //           fontSize: '12px',
      //         },
      //       },
      //       plotOptions: {
      //         column: {
      //           pointPadding: 0.2,
      //           borderWidth: 0,
      //           groupPadding: 0.35,
      //           dataLabels: {
      //             enabled: false,
      //             position: 'right',
      //             style: {
      //               fontSize: '12px',
      //             },
      //           },
      //         },
      //       },
      //       series: [
      //         {
      //           color: '#FFA149',
      //           name: $t['people_counts'],
      //           data: this.getSalaryByAge(data),
      //         },
      //       ],
      //     };
      //   },
      //   updateFunc(data, filter, option) {
      //     this[filter.label] = option.id;
      //     const seriesData = this.getSalaryByAge(data);

      //     return {
      //       series: [
      //         {
      //           name: '',
      //           data: seriesData,
      //         },
      //       ],
      //     };
      //   },
      // },
      // {
      //   type: 'income4',
      //   api: '/integrate/revenue?type=revenue_salary_gradation_data',
      //   chart: 'bar',
      //   year: 2023,
      //   pol: 3,
      //   salary_type: 'total_count',
      //   sort_type: 'inc',
      //   regions: [],
      //   filters: [
      //     {
      //       type: 'dropdown',
      //       label: 'year',
      //       options: [],
      //     },
      //     {
      //       type: 'dropdown',
      //       label: 'pol',
      //       options: [
      //         { id: 3, label: 'pol_both' },
      //         { id: 1, label: 'pol_man' },
      //         { id: 2, label: 'pol_woman' },
      //       ],
      //     },
      //     {
      //       type: 'dropdown',
      //       label: 'salary_type',
      //       options: [
      //         { id: 'do_mzp', label: 'do_mzp' },
      //         { id: 'mzp_do150000', label: 'mzp_do150000' },
      //         { id: 'ot150001_300000', label: 'ot150001_300000' },
      //         { id: 'ot300001_500000', label: 'ot300001_500000' },
      //         { id: 'ot500001_800000', label: 'ot500001_800000' },
      //         { id: '800000_', label: '800000_' },
      //         { id: 'total_count', label: 'total_count' },
      //       ]
      //     },
      //     {
      //       type: 'dropdown',
      //       label: 'sort_type',
      //       options: [
      //         { id: 'inc', label: 'inc' },
      //         { id: 'dec', label: 'dec' },
      //       ]
      //     },
      //   ],
      //   getSalaryByGradation(data) {
      //     const yearData = data[this.year]

      //     return this.getCategories(data).map((r) => {
      //       return yearData[r] ? yearData[r][this.pol][this.salary_type] : 0
      //     })
      //   },
      //   getCategories(data) {
      //     const yearData = data[this.year]

      //     return this.regions.sort((a, b) => {
      //       const aV = yearData[a] ? yearData[a][this.pol][this.salary_type] : 0
      //       const bV = yearData[b] ? yearData[b][this.pol][this.salary_type] : 0

      //       return (aV - bV) * (this.sort_type === 'inc' ? 1 : -1)
      //     })
      //   },
      //   normalize(data) {
      //     const obj = {};

      //     data.forEach((e) => {
      //       if (!e.year) return;

      //       if (!obj[e.year]) obj[e.year] = {};

      //       if (!obj[e.year][e.reg_ru]) {
      //         obj[e.year][e.reg_ru] = {
      //           name: e.reg_ru,
      //         };
      //       }

      //       if (!obj[e.year][e.reg_ru][e.pol]) {
      //         obj[e.year][e.reg_ru][e.pol] = e
      //       }
      //     });

      //     if (data.length) {
      //       this.filters[0].options = Object.keys(obj).sort((a, b) => b - a).map((y) => ({
      //         id: y,
      //         label: y,
      //       }))

      //       this.year = this.filters[0].options[0].id;

      //       this.regions = Object.keys(obj[this.year])
      //         .map((k) => {
      //           return k;
      //         }).filter((e) => e !== 'РК');
      //     }

      //     return obj;
      //   },
      //   parseFunc(data) {
      //     return {
      //       chart: {
      //         type: this.chart,
      //         scrollablePlotArea: {
      //           minWidth: 500
      //         }
      //       },
      //       title: {
      //         text: '',
      //       },
      //       legend: {
      //         enabled: false,
      //       },
      //       scrollbar: {
      //         enabled: true
      //       },
      //       xAxis: {
      //         labels: {
      //           style: {
      //             color: '#626A77',
      //             fontWeight: '400',
      //             fontSize: '12px',
      //           }
      //         },
      //         categories: this.getCategories(data),
      //         crosshair: true,
      //       },
      //       yAxis: {
      //         labels: {
      //           style: {
      //             color: '#626A77',
      //             fontWeight: '400',
      //             fontSize: '12px',
      //           }
      //         },
      //         min: 0,
      //         title: {
      //           text: ''
      //         }
      //       },
      //       tooltip: {
      //         backgroundColor: null,
      //         borderWidth: 0,
      //         shadow: false,
      //         useHTML: true,
      //         formatter: function () {
      //           return '<div style="width: 200px; cursor: pointer; white-space: wrap; font-weight: 400; font-size: 12px;"><div title="' + this.x  + '">' + this.x + ' - ' + Math.abs(this.y) + '</div><div/>';
      //         },
      //         style: {
      //           fontSize: '12px',
      //         },
      //         valueSuffix: '',
      //       },
      //       plotOptions: {
      //         column: {
      //           pointPadding: 0.2,
      //           borderWidth: 0,
      //           groupPadding: 0.35,
      //           dataLabels: {
      //             enabled: true,
      //             position: 'right',
      //             style: {
      //               fontSize: '8.5pt'
      //             }
      //           },
      //         }
      //       },
      //       series: [
      //         {
      //           color: '#FFA149',
      //           name: $t.salaryRanges.title1,
      //           data: this.getSalaryByGradation(data)
      //         },
      //       ]
      //     }
      //   },
      //   updateFunc(data, filter, option) {
      //     this[filter.label] = option.id;
      //     const seriesData = this.getSalaryByGradation(data)

      //     return {
      //       xAxis: {
      //         labels: {
      //           style: {
      //             color: '#626A77',
      //             fontWeight: '400',
      //             fontSize: '12px',
      //           }
      //         },
      //         categories: this.getCategories(data),
      //         crosshair: true,
      //       },
      //       series: [{
      //         name: '',
      //         data: seriesData,
      //       }],
      //     }
      //   }
      // },
      {
        type: 'income7',
        info: 'income_info_4',
        class: 'col-12 col-lg-6',
        api: '/integrate/revenue?type=revenue_for_nkz',
        chart: 'bar',
        index: 'smz',
        year: '2023',
        categories: [],
        filters: [
          {
            type: 'dropdown',
            label: 'index',
            options: [
              { id: 'smz', label: 'smz' },
              { id: 'count', label: 'count' },
            ],
          },
          {
            type: 'dropdown',
            label: 'year',
            options: [],
          },

        ],
        normalize(data) {
          const obj = {};
          const types = {};
          const years = [];

          data.forEach((e) => {
            if (!types[e.nkz_id]) types[e.nkz_id] = e.name

            if(!obj[e.year]){
              obj[e.year] = {};
            }
            
            if (!obj[e.year][e.nkz_id]) {
              obj[e.year][e.nkz_id] = {};
            }
            const pol = e.pol === '1' ? 'man' : 'woman';
            
            if (!obj[e.year][e.nkz_id][pol]) {
              obj[e.year][e.nkz_id][pol] = e;
            }
          
          });

          if (data.length) {
            this.filters[1].options = Object.keys(obj)
              .sort((a, b) => b - a)
              .map((y) => ({
                id: y,
                label: y,
              }));
            this.year = this.filters[1].options[0].id;

          }
        

          this.categories = Object.entries(types).map(([k, v]) => {
            return {
              id: k,
              label: v,
            };
          }).sort((a, b) => b.id - a.id);
          
          return obj;
        },
        getMale(data) {
          const inData = this.categories.map((category) => {
            const d = data[this.year][category.id]?.man;
            if (d) return d[this.index] * -1;
            return 0;
          });
          return inData;
        },
        getFeMale(data) {
          const inData = this.categories.map((category) => {
            const d = data[this.year][category.id]?.woman;
            if (d) return d[this.index];
            return 0;
          });
          return inData;
        },
        parseFunc(data) {
          return {
            credits: false,
            chart: {
              type: this.chart,
              height: 400,
            },
            title: {
              text: '',
            },
            legend: {
              enabled: true,
              itemStyle: {
                font: '10.5pt Trebuchet MS, Verdana, sans-serif',
              },
            },
            scrollbar: {
              enabled: true,
            },
            xAxis: [
              {
                reversed: false,
                categories: this.categories.map((e) => e.label),
                labels: {
                  step: 1,
                  style: {
                    color: '#626A77',
                    fontWeight: '400',
                    fontSize: '12px',
                    width: '180px',
                  },
                  useHTML: true,
                  formatter: function () {
                    const value = typeof this.value === 'string' ? this.value : this.value.name
                    return (
                      `<div align="center" style="width:180px;text-transform:lowercase;overflow:hidden;" title="${value.toLowerCase()}"><span style="text-transform:uppercase;">${value[0]}</span>${value.slice(1)}</div>`
                    );
                  },
                },
                accessibility: {
                  description: $t['pol_man'],
                },
              },
              {
                reversed: false,
                categories: this.categories.map((e) => e.label),
                linkedTo: 0,
                opposite: true,
                labels: {
                  enabled: false,
                  step: 1,
                  style: {
                    color: '#626A77',
                    fontWeight: '400',
                    fontSize: '12px',
                  },
                },
                accessibility: {
                  description: $t['pol_woman'],
                },
              },
            ],
            yAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                rotation: -90,
                formatter: (a, b) => {
                  if (Math.round(Math.abs(a.value) / 1000) > 0) return `${Math.abs(a.value) / 1000} ${$t['per_1000']}`;
                  return `${Math.abs(a.value) / 1000}`;
                },
              },
              scrollbar: {
                enabled: true,
              },
              accessibility: {
                rangeDescription: 'Range: 0 to 100000',
              },
              title: {
                text: '',
              },
            },
            tooltip: {
              backgroundColor: null,
              borderWidth: 0,
              shadow: false,
              useHTML: true,
              formatter: function () {
                return (
                  '<div style="width: 200px; cursor: pointer; white-space: wrap; font-weight: 400; font-size: 12px;"><div title="' +
                  this.x +
                  '">' +
                  this.x +
                  '</div><div><span><span style="display: inline-block;width: 8px;height: 8px; border-radius: 50%;margin-right: 10px;background: ' +
                  this.series.color +
                  '"></span style="font-weight: 500;">' +
                  this.series.name +
                  ' : </span>' +
                  Math.abs(this.y) +
                  '</div><div/>'
                );
              },
              style: {
                fontSize: '12px',
              },
              valueSuffix: '',
            },
            plotOptions: {
              series: {
                stacking: 'normal',
                borderRadius: '50%',
              },
            },
            series: [
              {
                name: $t['pol_man'],
                color: '#FFA149',
                labels: {
                  style: {
                    wordBreak: 'keep-all',
                    textOverflow: 'allow',
                  },
                },
                data: this.getMale(data),
              },
              {
                name: $t['pol_woman'],
                labels: {
                  style: {
                    wordBreak: 'keep-all',
                    textOverflow: 'allow',
                  },
                },
                color: '#90BE6D',
                data: this.getFeMale(data),
              },
            ],
          };
        },
        updateFunc(data, filter, option) {
          this[filter.label] = option.id;

          return {
            xAxis: [
              {
                reversed: false,
                categories: this.categories.map((e) => e.label),
                labels: {
                  step: 1,
                  style: {
                    color: '#626A77',
                    fontWeight: '400',
                    fontSize: '12px',
                    width: '180px',
                  },
                  useHTML: true,
                  formatter: function () {
                    const value = typeof this.value === 'string' ? this.value : this.value.name
                    return (
                      `<div align="center" style="width:180px;text-transform:lowercase;overflow:hidden;" title="${value.toLowerCase()}"><span style="text-transform:uppercase;">${value[0]}</span>${value.slice(1)}</div>`
                    );
                  },
                },
                accessibility: {
                  description: $t['pol_man'],
                },
              },
              {
                reversed: false,
                categories: this.categories.map((e) => e.label),
                linkedTo: 0,
                opposite: true,
                labels: {
                  enabled: false,
                  step: 1,
                  style: {
                    color: '#626A77',
                    fontWeight: '400',
                    fontSize: '12px',
                  },
                },
                accessibility: {
                  description: $t['pol_woman'],
                },
              },
            ],
            series: [
              {
                name: $t['pol_man'],
                color: '#FFA149',
                labels: {
                  style: {
                    wordBreak: 'keep-all',
                    textOverflow: 'allow',
                  },
                },
                data: this.getMale(data),
              },
              {
                name: $t['pol_woman'],
                labels: {
                  style: {
                    wordBreak: 'keep-all',
                    textOverflow: 'allow',
                  },
                },
                color: '#90BE6D',
                data: this.getFeMale(data),
              },
            ],
          };
        },
      },
    ],
  },
  {
    type: 'social_security',
    subtypes: [
      {
        type: 'social_security1',
        api: '/integrate/social/welfare?type=social_welfare_main',
        chart: 'column',
        info: 'social_security_info_1',
        hideAnalitics: true,
        region: 'PK',
        pol: 2,
        index: 'total_count',
        payment: 'Пенсия',
        years: [],
        normalize(data) {
          const obj = {};

          data.forEach((e) => {
            if (!e.year || (e.payment_name !== this.payment)) return;

            if (!obj[e.year]) obj[e.year] = {};
            if (!obj[e.year][e.reg_ru]) obj[e.year][e.reg_ru] = {};
            const pol = e.pol ? e.pol?.id : 0
            if (!obj[e.year][e.reg_ru][pol]) obj[e.year][e.reg_ru][pol] = {};
            if (!obj[e.year][e.reg_ru][pol][e.payment_name]) obj[e.year][e.reg_ru][pol][e.payment_name] = e
          });

          if (data.length) {
            this.years = Object.keys(obj).sort((a, b) => a - b);
          }

          return obj;
        },
        getSeries(data, years) {
          return [
            {
              name: '',
              color: '#F98500',
              data: years.map((y) => {
                try {
                  return data[+y][this.region][this.pol][this.payment][this.index];
                } catch (e) {
                  return 0;
                }
              }),
              stack: 'year',
            },
          ];
        },
        parseFunc(data) {
          let $this = this;
          return {
            credits: false,
            chart: {
              type: 'column',
            },
            title: {
              text: '',
            },
            xAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
              },
              categories: this.years,
            },
            yAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                formatter: (a) => {
                  if (a.value > 999999) return `${Math.abs(a.value / 1000000)} ${this.index === 'middle_sum' ? $t['tg_1mln'] : $t['per_1mln']}`;
                  if (Math.round(a.value / 1000) > 0) return `${a.value / 1000} ${this.index === 'middle_sum' ? $t['tg_1000'] : $t['per_1000']}`;
                  else return `${a.value / 1000}`;
                },
              },
              allowDecimals: true,
              min: 0,
              title: {
                text: '',
              },
            },
            scrollbar: {
              enabled: true,
            },
            legend: {
              enabled: false,
            },
            tooltip: {
              style: {
                fontSize: '12px',
                fontWeight: 400,
              },
              valueDecimals: 3,
              useHTML: false,
              formatter: function () {
                return `<span><span> ${this.x.name} ${$t['year_low']}</span><br>${$this.index === 'middle_sum' ? $t['srp'] : $t['person_short']
                  }: ${this.total.toLocaleString()}</span>`;
              },
            },
            plotOptions: {
              column: {
                stacking: 'normal',
              },
            },
            series: this.getSeries(data, this.years),
          };
        },
        updateFunc(data, filter, option) {
          this[filter.label] = option.id;
          let $this = this;

          return {
            tooltip: {
              style: {
                fontSize: '12px',
                fontWeight: 400,
              },
              valueDecimals: 3,
              useHTML: false,
              formatter: function () {
                return `<span><span> ${this.x.name} ${$t['year_low']}</span><br>${$this.index === 'middle_sum' ? $t['srp'] : $t['person_short']
                  }: ${this.total.toLocaleString()}</span>`;
              },
            },
            yAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                formatter: (a) => {
                  if (a.value > 999999) return `${Math.abs(a.value / 1000000)} ${this.index === 'middle_sum' ? $t['tg_1mln'] : $t['per_1mln']}`;
                  return `${a.value / 1000} ${this.index === 'middle_sum' ? $t['tg_1000'] : $t['per_1000']}`;
                },
              },
              allowDecimals: true,
              min: 0,
              title: {
                text: '',
              },
            },
            series: this.getSeries(data, this.years),
          };
        },
      },
      {
        type: 'social_security2',
        api: '/integrate/social/welfare?type=social_welfare_main',
        chart: 'column',
        info: 'social_security_info_2',
        hideAnalitics: true,
        region: 'PK',
        pol: 2,
        index: 'total_count',
        payment: 'Пособия многодетным семьям',
        years: [],
        normalize(data) {
          const obj = {};

          data.forEach((e) => {
            if (!e.year || (e.payment_name !== this.payment)) return;

            if (!obj[e.year]) obj[e.year] = {};
            if (!obj[e.year][e.reg_ru]) obj[e.year][e.reg_ru] = {};
            const pol = e.pol ? e.pol?.id : 0
            if (!obj[e.year][e.reg_ru][pol]) obj[e.year][e.reg_ru][pol] = {};
            if (!obj[e.year][e.reg_ru][pol][e.payment_name]) obj[e.year][e.reg_ru][pol][e.payment_name] = e
          });

          if (data.length) {
            this.years = Object.keys(obj).sort((a, b) => a - b);
          }

          return obj;
        },
        getSeries(data, years) {
          return [
            {
              name: '',
              color: '#F98500',
              data: years.map((y) => {
                try {
                  return data[+y][this.region][this.pol][this.payment][this.index];
                } catch (e) {
                  return 0;
                }
              }),
              stack: 'year',
            },
          ];
        },
        parseFunc(data) {
          let $this = this;
          return {
            credits: false,
            chart: {
              type: 'column',
            },
            title: {
              text: '',
            },
            xAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
              },
              categories: this.years,
            },
            yAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                formatter: (a) => {
                  if (a.value > 999999) return `${Math.abs(a.value / 1000000)} ${this.index === 'middle_sum' ? $t['tg_1mln'] : $t['per_1mln']}`;
                  if (Math.round(a.value / 1000) > 0) return `${a.value / 1000} ${this.index === 'middle_sum' ? $t['tg_1000'] : $t['per_1000']}`;
                  else return `${a.value / 1000}`;
                },
              },
              allowDecimals: true,
              min: 0,
              title: {
                text: '',
              },
            },
            scrollbar: {
              enabled: true,
            },
            legend: {
              enabled: false,
            },
            tooltip: {
              style: {
                fontSize: '12px',
                fontWeight: 400,
              },
              valueDecimals: 3,
              useHTML: false,
              formatter: function () {
                return `<span><span> ${this.x.name} ${$t['year_low']}</span><br>${$this.index === 'middle_sum' ? $t['srp'] : $t['person_short']
                  }: ${this.total.toLocaleString()}</span>`;
              },
            },
            plotOptions: {
              column: {
                stacking: 'normal',
              },
            },
            series: this.getSeries(data, this.years),
          };
        },
        updateFunc(data, filter, option) {
          this[filter.label] = option.id;
          let $this = this;

          return {
            tooltip: {
              style: {
                fontSize: '12px',
                fontWeight: 400,
              },
              valueDecimals: 3,
              useHTML: false,
              formatter: function () {
                return `<span><span> ${this.x.name} ${$t['year_low']}</span><br>${$this.index === 'middle_sum' ? $t['srp'] : $t['person_short']
                  }: ${this.total.toLocaleString()}</span>`;
              },
            },
            yAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                formatter: (a) => {
                  if (a.value > 999999) return `${Math.abs(a.value / 1000000)} ${this.index === 'middle_sum' ? $t['tg_1mln'] : $t['per_1mln']}`;
                  return `${a.value / 1000} ${this.index === 'middle_sum' ? $t['tg_1000'] : $t['per_1000']}`;
                },
              },
              allowDecimals: true,
              min: 0,
              title: {
                text: '',
              },
            },
            series: this.getSeries(data, this.years),
          };
        },
      },
      {
        type: 'social_security3',
        api: '/integrate/social/welfare?type=social_welfare_main',
        chart: 'column',
        info: 'social_security_info_3',
        hideAnalitics: true,
        region: 'PK',
        pol: 2,
        index: 'total_count',
        payment: 'Пособия по уходу за ребенком до года/до полутора лет',
        years: [],
        normalize(data) {
          const obj = {};

          data.forEach((e) => {
            if (!e.year || (e.payment_name !== this.payment)) return;

            if (!obj[e.year]) obj[e.year] = {};
            if (!obj[e.year][e.reg_ru]) obj[e.year][e.reg_ru] = {};
            const pol = e.pol ? e.pol?.id : 0
            if (!obj[e.year][e.reg_ru][pol]) obj[e.year][e.reg_ru][pol] = {};
            if (!obj[e.year][e.reg_ru][pol][e.payment_name]) obj[e.year][e.reg_ru][pol][e.payment_name] = e
          });

          if (data.length) {
            this.years = Object.keys(obj).sort((a, b) => a - b);
          }

          return obj;
        },
        getSeries(data, years) {
          return [
            {
              name: '',
              color: '#F98500',
              data: years.map((y) => {
                try {
                  return data[+y][this.region][this.pol][this.payment][this.index];
                } catch (e) {
                  return 0;
                }
              }),
              stack: 'year',
            },
          ];
        },
        parseFunc(data) {
          let $this = this;
          return {
            credits: false,
            chart: {
              type: 'column',
            },
            title: {
              text: '',
            },
            xAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
              },
              categories: this.years,
            },
            yAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                formatter: (a) => {
                  if (a.value > 999999) return `${Math.abs(a.value / 1000000)} ${this.index === 'middle_sum' ? $t['tg_1mln'] : $t['per_1mln']}`;
                  if (Math.round(a.value / 1000) > 0) return `${a.value / 1000} ${this.index === 'middle_sum' ? $t['tg_1000'] : $t['per_1000']}`;
                  else return `${a.value / 1000}`;
                },
              },
              allowDecimals: true,
              min: 0,
              title: {
                text: '',
              },
            },
            scrollbar: {
              enabled: true,
            },
            legend: {
              enabled: false,
            },
            tooltip: {
              style: {
                fontSize: '12px',
                fontWeight: 400,
              },
              valueDecimals: 3,
              useHTML: false,
              formatter: function () {
                return `<span><span> ${this.x.name} ${$t['year_low']}</span><br>${$this.index === 'middle_sum' ? $t['srp'] : $t['person_short']
                  }: ${this.total.toLocaleString()}</span>`;
              },
            },
            plotOptions: {
              column: {
                stacking: 'normal',
              },
            },
            series: this.getSeries(data, this.years),
          };
        },
        updateFunc(data, filter, option) {
          this[filter.label] = option.id;
          let $this = this;

          return {
            tooltip: {
              style: {
                fontSize: '12px',
                fontWeight: 400,
              },
              valueDecimals: 3,
              useHTML: false,
              formatter: function () {
                return `<span><span> ${this.x.name} ${$t['year_low']}</span><br>${$this.index === 'middle_sum' ? $t['srp'] : $t['person_short']
                  }: ${this.total.toLocaleString()}</span>`;
              },
            },
            yAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                formatter: (a) => {
                  if (a.value > 999999) return `${Math.abs(a.value / 1000000)} ${this.index === 'middle_sum' ? $t['tg_1mln'] : $t['per_1mln']}`;
                  return `${a.value / 1000} ${this.index === 'middle_sum' ? $t['tg_1000'] : $t['per_1000']}`;
                },
              },
              allowDecimals: true,
              min: 0,
              title: {
                text: '',
              },
            },
            series: this.getSeries(data, this.years),
          };
        },
      },
      {
        type: 'social_security4',
        api: '/integrate/social/welfare?type=social_welfare_main',
        chart: 'column',
        info: 'social_security_info_4',
        hideAnalitics: true,
        region: 'PK',
        pol: 2,
        index: 'total_count',
        payment: 'ГСП по потере кормильца',
        years: [],
        normalize(data) {
          const obj = {};

          data.forEach((e) => {
            if (!e.year || (e.payment_name !== this.payment)) return;

            if (!obj[e.year]) obj[e.year] = {};
            if (!obj[e.year][e.reg_ru]) obj[e.year][e.reg_ru] = {};
            const pol = e.pol ? e.pol?.id : 0
            if (!obj[e.year][e.reg_ru][pol]) obj[e.year][e.reg_ru][pol] = {};
            if (!obj[e.year][e.reg_ru][pol][e.payment_name]) obj[e.year][e.reg_ru][pol][e.payment_name] = e
          });

          if (data.length) {
            this.years = Object.keys(obj).sort((a, b) => a - b);
          }

          return obj;
        },
        getSeries(data, years) {
          return [
            {
              name: '',
              color: '#F98500',
              data: years.map((y) => {
                try {
                  return data[+y][this.region][this.pol][this.payment][this.index];
                } catch (e) {
                  return 0;
                }
              }),
              stack: 'year',
            },
          ];
        },
        parseFunc(data) {
          let $this = this;
          return {
            credits: false,
            chart: {
              type: 'column',
            },
            title: {
              text: '',
            },
            xAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
              },
              categories: this.years,
            },
            yAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                formatter: (a) => {
                  if (a.value > 999999) return `${Math.abs(a.value / 1000000)} ${this.index === 'middle_sum' ? $t['tg_1mln'] : $t['per_1mln']}`;
                  if (Math.round(a.value / 1000) > 0) return `${a.value / 1000} ${this.index === 'middle_sum' ? $t['tg_1000'] : $t['per_1000']}`;
                  else return `${a.value / 1000}`;
                },
              },
              allowDecimals: true,
              min: 0,
              title: {
                text: '',
              },
            },
            scrollbar: {
              enabled: true,
            },
            legend: {
              enabled: false,
            },
            tooltip: {
              style: {
                fontSize: '12px',
                fontWeight: 400,
              },
              valueDecimals: 3,
              useHTML: false,
              formatter: function () {
                return `<span><span> ${this.x.name} ${$t['year_low']}</span><br>${$this.index === 'middle_sum' ? $t['srp'] : $t['person_short']
                  }: ${this.total.toLocaleString()}</span>`;
              },
            },
            plotOptions: {
              column: {
                stacking: 'normal',
              },
            },
            series: this.getSeries(data, this.years),
          };
        },
        updateFunc(data, filter, option) {
          this[filter.label] = option.id;
          let $this = this;

          return {
            tooltip: {
              style: {
                fontSize: '12px',
                fontWeight: 400,
              },
              valueDecimals: 3,
              useHTML: false,
              formatter: function () {
                return `<span><span> ${this.x.name} ${$t['year_low']}</span><br>${$this.index === 'middle_sum' ? $t['srp'] : $t['person_short']
                  }: ${this.total.toLocaleString()}</span>`;
              },
            },
            yAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                formatter: (a) => {
                  if (a.value > 999999) return `${Math.abs(a.value / 1000000)} ${this.index === 'middle_sum' ? $t['tg_1mln'] : $t['per_1mln']}`;
                  return `${a.value / 1000} ${this.index === 'middle_sum' ? $t['tg_1000'] : $t['per_1000']}`;
                },
              },
              allowDecimals: true,
              min: 0,
              title: {
                text: '',
              },
            },
            series: this.getSeries(data, this.years),
          };
        },
      },
      {
        type: 'social_security1',
        api: '/integrate/social/welfare?type=social_welfare_main',
        chart: 'bar',
        info: 'social_security_info_1',
        hideMain: true,
        year: '2023',
        pol: 2,
        sort_type: 'inc',
        payment: 'Пенсия',
        index: 'srp',
        filters: [
          {
            type: 'dropdown',
            label: 'year',
            options: [],
          },
          {
            type: 'dropdown',
            label: 'pol',
            options: [
              { id: 2, label: 'basic' },
              { id: 1, label: 'male' },
              { id: 0, label: 'female' },
            ],
          },
          {
            type: 'dropdown',
            label: 'index',
            options: [
              { id: 'srp', label: 'srp' },
              { id: 'total_count', label: 'count' },
            ],
          },
          {
            type: 'dropdown',
            label: 'sort_type',
            options: [
              { id: 'inc', label: 'inc' },
              { id: 'dec', label: 'dec' },
            ],
          },
        ],
        regions: [],
        normalize(data) {
          const obj = {};

          data.forEach((e) => {
            if (!e.year) return;
            if (!obj[e.year]) obj[e.year] = {};
            if (!obj[e.year][e.reg_ru]) obj[e.year][e.reg_ru] = {};
            if (e.payment_name !== this.payment) return
            const pol = e.pol ? e.pol.id : 0
            if (!obj[e.year][e.reg_ru][pol]) obj[e.year][e.reg_ru][pol] = {};
            if (!obj[e.year][e.reg_ru][pol][e.payment_name]) {
              obj[e.year][e.reg_ru][pol][e.payment_name] = {
                srp: e.srp,
                total_count: e.total_count
              }
            }
          });

          if (data.length) {
            this.filters[0].options = Object.keys(obj)
              .sort((a, b) => b - a)
              .map((y) => ({
                id: y,
                label: y,
              }));
            this.year = this.filters[0].options[0].id;

            this.regions = Object.keys(obj[this.year]).filter((e) => e !== 'PK');
          }

          return obj;
        },
        getData(data) {
          return this.regions
            .map((c) => {
              try {
                return data[this.year][c][this.pol][this.payment][this.index];
              } catch (e) {
                return [];
              }
            })
            .sort((a, b) => (a - b) * (this.sort_type === 'inc' ? 1 : -1));
        },
        getCategories(data) {
          const v = data[this.year];
          return this.regions.sort((a, b) => {
            try {
              const aV = v[a] ? v[a][this.pol][this.payment][this.index] : 0;
              const bV = v[b] ? v[b][this.pol][this.payment][this.index] : 0;
              return (aV - bV) * (this.sort_type === 'inc' ? 1 : -1);
            } catch (e) {
              return 0;
            }
          });
        },
        parseFunc(data) {
          const seriesData = this.getData(data);
          const categories = this.getCategories(data);

          return {
            credits: false,
            chart: {
              type: this.chart,
            },
            title: {
              text: '',
            },
            xAxis: {
              categories: categories,
              labels: {
                useHTML: true,
                style: {
                  width: '150px',
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                },
                step: 1,
                formatter: function () {
                  return (
                    '<div align="center" style="width:150px;text-transform:capitalize;overflow:hidden;">' +
                    this.value +
                    '</div>'
                  );
                },
              },
            },
            yAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                formatter: (a) => {
                  if (a.value / 1000 > 0) return `${a.value / 1000} ${$t['tg_1000']}`;
                  return `${a.value / 1000}`
                },
                rotation: -90,
              },
              scrollbar: {
                enabled: true,
              },
              accessibility: {
                rangeDescription: 'Range: 0 to 100000',
              },
              title: {
                text: '',
              },
            },
            scrollbar: {
              enabled: true,
            },
            legend: {
              enabled: false,
            },
            tooltip: {
              backgroundColor: null,
              borderWidth: 0,
              shadow: false,
              useHTML: true,
              formatter: function () {
                return (
                  '<div style="width: 200px; cursor: pointer; white-space: wrap; font-weight: 400; font-size: 12px;"><div title="' +
                  this.x +
                  '">' +
                  this.x +
                  ' - ' +
                  Math.abs(this.y) +
                  '</div><div/>'
                );
              },
              style: {
                fontSize: '12px',
              },
              valueSuffix: '',
            },
            series: [
              {
                name: '',
                color: '#FFA149',
                data: seriesData,
              },
            ],
            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {},
                },
              ],
            },
          };
        },
        updateFunc(data, filter, option) {
          this[filter.label] = option.id;
          const seriesData = this.getData(data);
          const categories = this.getCategories(data);

          return {
            xAxis: {
              categories: categories,
              labels: {
                useHTML: true,
                style: {
                  width: '150px',
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                },
                step: 1,
                formatter: function () {
                  return (
                    '<div align="center" style="width:150px;text-transform:capitalize;overflow:hidden;">' +
                    this.value +
                    '</div>'
                  );
                },
              },
            },
            tooltip: {
              backgroundColor: null,
              borderWidth: 0,
              shadow: false,
              useHTML: true,
              formatter: function () {
                return (
                  '<div style="width: 200px; cursor: pointer; white-space: wrap; font-weight: 400; font-size: 12px;"><div title="' +
                  this.x +
                  '">' +
                  this.x +
                  ' - ' +
                  Math.abs(this.y) +
                  '</div><div/>'
                );
              },
              style: {
                fontSize: '12px',
              },
              valueSuffix: '',
            },
            series: [
              {
                name: '',
                data: seriesData,
              },
            ],
          };
        },
      },
      {
        type: 'social_security2',
        api: '/integrate/social/welfare?type=social_welfare_main',
        chart: 'bar',
        info: 'social_security_info_2',
        hideMain: true,
        year: '2023',
        pol: 2,
        sort_type: 'inc',
        payment: 'Пособия многодетным семьям',
        index: 'srp',
        filters: [
          {
            type: 'dropdown',
            label: 'year',
            options: [],
          },
          {
            type: 'dropdown',
            label: 'pol',
            options: [
              { id: 2, label: 'basic' },
              { id: 1, label: 'male' },
              { id: 0, label: 'female' },
            ],
          },
          {
            type: 'dropdown',
            label: 'index',
            options: [
              { id: 'srp', label: 'srp' },
              { id: 'total_count', label: 'count' },
            ],
          },
          {
            type: 'dropdown',
            label: 'sort_type',
            options: [
              { id: 'inc', label: 'inc' },
              { id: 'dec', label: 'dec' },
            ],
          },
        ],
        regions: [],
        normalize(data) {
          const obj = {};

          data.forEach((e) => {
            if (!e.year || (e.payment_name !== this.payment)) return;

            if (!obj[e.year]) obj[e.year] = {};
            if (!obj[e.year][e.reg_ru]) obj[e.year][e.reg_ru] = {};
            const pol = e.pol ? e.pol?.id : 0
            if (!obj[e.year][e.reg_ru][pol]) obj[e.year][e.reg_ru][pol] = {};
            if (!obj[e.year][e.reg_ru][pol][e.payment_name]) obj[e.year][e.reg_ru][pol][e.payment_name] = e
          });

          if (data.length) {
            this.filters[0].options = Object.keys(obj)
              .sort((a, b) => b - a)
              .map((y) => ({
                id: y,
                label: y,
              }));
            this.year = this.filters[0].options[0].id;

            this.regions = Object.keys(obj[this.year]).filter((e) => e !== 'PK');
          }

          return obj;
        },
        getData(data) {
          return this.regions
            .map((c) => {
              try {
                return data[this.year][c][this.pol][this.payment][this.index];
              } catch (e) {
                return [];
              }
            })
            .sort((a, b) => (a - b) * (this.sort_type === 'inc' ? 1 : -1));
        },
        getCategories(data) {
          const v = data[this.year];
          return this.regions.sort((a, b) => {
            try {
              const aV = v[a] ? v[a][this.pol][this.payment][this.index] : 0;
              const bV = v[b] ? v[b][this.pol][this.payment][this.index] : 0;
              return (aV - bV) * (this.sort_type === 'inc' ? 1 : -1);
            } catch (e) {
              return 0;
            }
          });
        },
        parseFunc(data) {
          const seriesData = this.getData(data);
          const categories = this.getCategories(data);

          return {
            credits: false,
            chart: {
              type: this.chart,
            },
            title: {
              text: '',
            },
            xAxis: {
              categories: categories,
              labels: {
                useHTML: true,
                style: {
                  width: '150px',
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                },
                step: 1,
                formatter: function () {
                  return (
                    '<div align="center" style="width:150px;text-transform:capitalize;overflow:hidden;">' +
                    this.value +
                    '</div>'
                  );
                },
              },
            },
            yAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                formatter: (a) => {
                  if (a.value / 1000 > 0) return `${a.value / 1000} ${$t['tg_1000']}`;
                  return `${a.value / 1000}`
                },
                rotation: -90,
              },
              scrollbar: {
                enabled: true,
              },
              accessibility: {
                rangeDescription: 'Range: 0 to 100000',
              },
              title: {
                text: '',
              },
            },
            scrollbar: {
              enabled: true,
            },
            legend: {
              enabled: false,
            },
            tooltip: {
              backgroundColor: null,
              borderWidth: 0,
              shadow: false,
              useHTML: true,
              formatter: function () {
                return (
                  '<div style="width: 200px; cursor: pointer; white-space: wrap; font-weight: 400; font-size: 12px;"><div title="' +
                  this.x +
                  '">' +
                  this.x +
                  ' - ' +
                  Math.abs(this.y) +
                  '</div><div/>'
                );
              },
              style: {
                fontSize: '12px',
              },
              valueSuffix: '',
            },
            series: [
              {
                name: '',
                color: '#FFA149',
                data: seriesData,
              },
            ],
            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {},
                },
              ],
            },
          };
        },
        updateFunc(data, filter, option) {
          this[filter.label] = option.id;
          const seriesData = this.getData(data);

          return {
            xAxis: {
              categories: this.getCategories(data),
              labels: {
                useHTML: true,
                style: {
                  width: '150px',
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                },
                step: 1,
                formatter: function () {
                  return (
                    '<div align="center" style="width:150px;text-transform:capitalize;overflow:hidden;">' +
                    this.value +
                    '</div>'
                  );
                },
              },
            },
            tooltip: {
              backgroundColor: null,
              borderWidth: 0,
              shadow: false,
              useHTML: true,
              formatter: function () {
                return (
                  '<div style="width: 200px; cursor: pointer; white-space: wrap; font-weight: 400; font-size: 12px;"><div title="' +
                  this.x +
                  '">' +
                  this.x +
                  ' - ' +
                  Math.abs(this.y) +
                  '</div><div/>'
                );
              },
              style: {
                fontSize: '12px',
              },
              valueSuffix: '',
            },
            series: [
              {
                name: '',
                data: seriesData,
              },
            ],
          };
        },
      },
      {
        type: 'social_security3',
        api: '/integrate/social/welfare?type=social_welfare_main',
        chart: 'bar',
        info: 'social_security_info_3',
        hideMain: true,
        year: '2023',
        pol: 2,
        sort_type: 'inc',
        payment: 'Пособия по уходу за ребенком до года/до полутора лет',
        index: 'srp',
        filters: [
          {
            type: 'dropdown',
            label: 'year',
            options: [],
          },
          {
            type: 'dropdown',
            label: 'pol',
            options: [
              { id: 2, label: 'basic' },
              { id: 1, label: 'male' },
              { id: 0, label: 'female' },
            ],
          },
          {
            type: 'dropdown',
            label: 'index',
            options: [
              { id: 'srp', label: 'srp' },
              { id: 'total_count', label: 'count' },
            ],
          },
          {
            type: 'dropdown',
            label: 'sort_type',
            options: [
              { id: 'inc', label: 'inc' },
              { id: 'dec', label: 'dec' },
            ],
          },
        ],
        regions: [],
        normalize(data) {
          const obj = {};

          data.forEach((e) => {
            if (!e.year || (e.payment_name !== this.payment)) return;

            if (!obj[e.year]) obj[e.year] = {};
            if (!obj[e.year][e.reg_ru]) obj[e.year][e.reg_ru] = {};
            const pol = e.pol ? e.pol?.id : 0
            if (!obj[e.year][e.reg_ru][pol]) obj[e.year][e.reg_ru][pol] = {};
            if (!obj[e.year][e.reg_ru][pol][e.payment_name]) obj[e.year][e.reg_ru][pol][e.payment_name] = e
          });

          if (data.length) {
            this.filters[0].options = Object.keys(obj)
              .sort((a, b) => b - a)
              .map((y) => ({
                id: y,
                label: y,
              }));
            this.year = this.filters[0].options[0].id;

            this.regions = Object.keys(obj[this.year]).filter((e) => e !== 'PK');
          }

          return obj;
        },
        getData(data) {
          return this.regions
            .map((c) => {
              try {
                return data[this.year][c][this.pol][this.payment][this.index];
              } catch (e) {
                return [];
              }
            })
            .sort((a, b) => (a - b) * (this.sort_type === 'inc' ? 1 : -1));
        },
        getCategories(data) {
          const v = data[this.year];
          return this.regions.sort((a, b) => {
            try {
              const aV = v[a] ? v[a][this.pol][this.payment][this.index] : 0;
              const bV = v[b] ? v[b][this.pol][this.payment][this.index] : 0;
              return (aV - bV) * (this.sort_type === 'inc' ? 1 : -1);
            } catch (e) {
              return 0;
            }
          });
        },
        parseFunc(data) {
          const seriesData = this.getData(data);
          const categories = this.getCategories(data);

          return {
            credits: false,
            chart: {
              type: this.chart,
            },
            title: {
              text: '',
            },
            xAxis: {
              categories: categories,
              labels: {
                useHTML: true,
                style: {
                  width: '150px',
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                },
                step: 1,
                formatter: function () {
                  return (
                    '<div align="center" style="width:150px;text-transform:capitalize;overflow:hidden;">' +
                    this.value +
                    '</div>'
                  );
                },
              },
            },
            yAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                formatter: (a) => {
                  if (a.value / 1000 > 0) return `${a.value / 1000} ${$t['tg_1000']}`;
                  return `${a.value / 1000}`
                },
                rotation: -90,
              },
              scrollbar: {
                enabled: true,
              },
              accessibility: {
                rangeDescription: 'Range: 0 to 100000',
              },
              title: {
                text: '',
              },
            },
            scrollbar: {
              enabled: true,
            },
            legend: {
              enabled: false,
            },
            tooltip: {
              backgroundColor: null,
              borderWidth: 0,
              shadow: false,
              useHTML: true,
              formatter: function () {
                return (
                  '<div style="width: 200px; cursor: pointer; white-space: wrap; font-weight: 400; font-size: 12px;"><div title="' +
                  this.x +
                  '">' +
                  this.x +
                  ' - ' +
                  Math.abs(this.y) +
                  '</div><div/>'
                );
              },
              style: {
                fontSize: '12px',
              },
              valueSuffix: '',
            },
            series: [
              {
                name: '',
                color: '#FFA149',
                data: seriesData,
              },
            ],
            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {},
                },
              ],
            },
          };
        },
        updateFunc(data, filter, option) {
          this[filter.label] = option.id;
          const seriesData = this.getData(data);

          return {
            xAxis: {
              categories: this.getCategories(data),
              labels: {
                useHTML: true,
                style: {
                  width: '150px',
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                },
                step: 1,
                formatter: function () {
                  return (
                    '<div align="center" style="width:150px;text-transform:capitalize;overflow:hidden;">' +
                    this.value +
                    '</div>'
                  );
                },
              },
            },
            tooltip: {
              backgroundColor: null,
              borderWidth: 0,
              shadow: false,
              useHTML: true,
              formatter: function () {
                return (
                  '<div style="width: 200px; cursor: pointer; white-space: wrap; font-weight: 400; font-size: 12px;"><div title="' +
                  this.x +
                  '">' +
                  this.x +
                  ' - ' +
                  Math.abs(this.y) +
                  '</div><div/>'
                );
              },
              style: {
                fontSize: '12px',
              },
              valueSuffix: '',
            },
            series: [
              {
                name: '',
                data: seriesData,
              },
            ],
          };
        },
      },
      {
        type: 'social_security4',
        api: '/integrate/social/welfare?type=social_welfare_main',
        chart: 'bar',
        info: 'social_security_info_4',
        hideMain: true,
        year: '2023',
        pol: 2,
        sort_type: 'inc',
        payment: 'ГСП по потере кормильца',
        index: 'srp',
        filters: [
          {
            type: 'dropdown',
            label: 'year',
            options: [],
          },
          {
            type: 'dropdown',
            label: 'pol',
            options: [
              { id: 2, label: 'basic' },
              { id: 1, label: 'male' },
              { id: 0, label: 'female' },
            ],
          },
          {
            type: 'dropdown',
            label: 'index',
            options: [
              { id: 'srp', label: 'srp' },
              { id: 'total_count', label: 'count' },
            ],
          },
          {
            type: 'dropdown',
            label: 'sort_type',
            options: [
              { id: 'inc', label: 'inc' },
              { id: 'dec', label: 'dec' },
            ],
          },
        ],
        regions: [],
        normalize(data) {
          const obj = {};

          data.forEach((e) => {
            if (!e.year || (e.payment_name !== this.payment)) return;

            if (!obj[e.year]) obj[e.year] = {};
            if (!obj[e.year][e.reg_ru]) obj[e.year][e.reg_ru] = {};
            const pol = e.pol ? e.pol?.id : 0
            if (!obj[e.year][e.reg_ru][pol]) obj[e.year][e.reg_ru][pol] = {};
            if (!obj[e.year][e.reg_ru][pol][e.payment_name]) obj[e.year][e.reg_ru][pol][e.payment_name] = e
          });

          if (data.length) {
            this.filters[0].options = Object.keys(obj)
              .sort((a, b) => b - a)
              .map((y) => ({
                id: y,
                label: y,
              }));
            this.year = this.filters[0].options[0].id;

            this.regions = Object.keys(obj[this.year]).filter((e) => e !== 'PK');
          }

          return obj;
        },
        getData(data) {
          return this.regions
            .map((c) => {
              try {
                return data[this.year][c][this.pol][this.payment][this.index];
              } catch (e) {
                return [];
              }
            })
            .sort((a, b) => (a - b) * (this.sort_type === 'inc' ? 1 : -1));
        },
        getCategories(data) {
          const v = data[this.year];
          return this.regions.sort((a, b) => {
            try {
              const aV = v[a] ? v[a][this.pol][this.payment][this.index] : 0;
              const bV = v[b] ? v[b][this.pol][this.payment][this.index] : 0;
              return (aV - bV) * (this.sort_type === 'inc' ? 1 : -1);
            } catch (e) {
              return 0;
            }
          });
        },
        parseFunc(data) {
          const seriesData = this.getData(data);
          const categories = this.getCategories(data);

          return {
            credits: false,
            chart: {
              type: this.chart,
            },
            title: {
              text: '',
            },
            xAxis: {
              categories: categories,
              labels: {
                useHTML: true,
                style: {
                  width: '150px',
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                },
                step: 1,
                formatter: function () {
                  return (
                    '<div align="center" style="width:150px;text-transform:capitalize;overflow:hidden;">' +
                    this.value +
                    '</div>'
                  );
                },
              },
            },
            yAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                formatter: (a) => {
                  if (a.value / 1000 > 0) return `${a.value / 1000} ${$t['tg_1000']}`;
                  return `${a.value / 1000}`
                },
                rotation: -90,
              },
              scrollbar: {
                enabled: true,
              },
              accessibility: {
                rangeDescription: 'Range: 0 to 100000',
              },
              title: {
                text: '',
              },
            },
            scrollbar: {
              enabled: true,
            },
            legend: {
              enabled: false,
            },
            tooltip: {
              backgroundColor: null,
              borderWidth: 0,
              shadow: false,
              useHTML: true,
              formatter: function () {
                return (
                  '<div style="width: 200px; cursor: pointer; white-space: wrap; font-weight: 400; font-size: 12px;"><div title="' +
                  this.x +
                  '">' +
                  this.x +
                  ' - ' +
                  Math.abs(this.y) +
                  '</div><div/>'
                );
              },
              style: {
                fontSize: '12px',
              },
              valueSuffix: '',
            },
            series: [
              {
                name: '',
                color: '#FFA149',
                data: seriesData,
              },
            ],
            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {},
                },
              ],
            },
          };
        },
        updateFunc(data, filter, option) {
          this[filter.label] = option.id;
          const seriesData = this.getData(data);

          return {
            xAxis: {
              categories: this.getCategories(data),
              labels: {
                useHTML: true,
                style: {
                  width: '150px',
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                },
                step: 1,
                formatter: function () {
                  return (
                    '<div align="center" style="width:150px;text-transform:capitalize;overflow:hidden;">' +
                    this.value +
                    '</div>'
                  );
                },
              },
            },
            tooltip: {
              backgroundColor: null,
              borderWidth: 0,
              shadow: false,
              useHTML: true,
              formatter: function () {
                return (
                  '<div style="width: 200px; cursor: pointer; white-space: wrap; font-weight: 400; font-size: 12px;"><div title="' +
                  this.x +
                  '">' +
                  this.x +
                  ' - ' +
                  Math.abs(this.y) +
                  '</div><div/>'
                );
              },
              style: {
                fontSize: '12px',
              },
              valueSuffix: '',
            },
            series: [
              {
                name: '',
                data: seriesData,
              },
            ],
          };
        },
      },
      {
        type: 'social_security8',
        api: '/integrate/social/welfare?type=social_welfare_main',
        chart: 'bar',
        info: 'social_security_info_6',
        hideMain: true,
        year: '2023',
        pol: 2,
        sort_type: 'inc',
        payment: 'По инвалидности',
        age: 'total_count',
        disability_group: '5',
        index: 'srp',
        filters: [
          {
            type: 'dropdown',
            label: 'year',
            options: [],
          },
          {
            type: 'dropdown',
            label: 'pol',
            options: [
              { id: 2, label: 'basic' },
              { id: 1, label: 'male' },
              { id: 0, label: 'female' },
            ],
          },
          {
            type: 'dropdown',
            label: 'disability_group',
            options: [
              { id: '5', label: 'basic' },
              { id: '1', label: '1' },
              { id: '2', label: '2' },
              { id: '3', label: '3' },
              { id: '4', label: 'childrenDisabilities16' },
            ],
          },
          {
            type: 'dropdown',
            label: 'age',
            options: [
              { id: 'total_count', label: 'all_ages_count' },
              { id: 'count_0_18', label: 'count_0_18' },
              { id: 'count_19_25', label: 'count_19_25' },
              { id: 'count_26_35', label: 'count_26_35' },
              { id: 'count_36_55', label: 'count_36_55' },
              { id: 'count_56_63', label: 'count_56_63' },
              { id: 'count_63_old', label: 'count_63_old' },
            ],
          },
          {
            type: 'dropdown',
            label: 'sort_type',
            options: [
              { id: 'inc', label: 'inc' },
              { id: 'dec', label: 'dec' },
            ],
          },
        ],
        regions: [],
        normalize(data) {
          const obj = {};

          data.forEach((e) => {
            // if (e.payment_name === 'По инвал') {
            //   e.payment_name = this.payment
            // }
            if (e.payment_name === 'Дети с инвалидностью до 16 лет') {
              e.payment_name = this.payment
            }
            if (!e.year || (e.payment_name !== this.payment)) return;

            if (!obj[e.year]) obj[e.year] = {};
            if (!obj[e.year][e.reg_ru]) obj[e.year][e.reg_ru] = {};
            const pol = e.pol ? e.pol?.id : 0
            if (!obj[e.year][e.reg_ru][pol]) obj[e.year][e.reg_ru][pol] = {};
            if (!obj[e.year][e.reg_ru][pol][e.payment_name]) obj[e.year][e.reg_ru][pol][e.payment_name] = {}
            if (!obj[e.year][e.reg_ru][pol][e.payment_name][e.disability_group]) {
              obj[e.year][e.reg_ru][pol][e.payment_name][e.disability_group] = e
            }
            if (!obj[e.year][e.reg_ru][pol][e.payment_name][5]) {
              obj[e.year][e.reg_ru][pol][e.payment_name][5] = {
                total_count: e.total_count,
                count_0_18: e.count_0_18,
                count_19_25: e.count_19_25,
                count_26_35: e.count_26_35,
                count_36_55: e.count_36_55,
                count_56_63: e.count_56_63,
                count_63_old: e.count_63_old,
              }
            } else {
              const o = obj[e.year][e.reg_ru][pol][e.payment_name][5]
              o.total_count = o.total_count + e.total_count
              o.count_0_18 = o.count_0_18 + e.count_0_18
              o.count_19_25 = o.count_19_25 + e.count_19_25
              o.count_26_35 = o.count_26_35 + e.count_26_35
              o.count_36_55 = o.count_36_55 + e.count_36_55
              o.count_56_63 = o.count_56_63 + e.count_56_63
              o.count_63_old = o.count_63_old + e.count_63_old
            }
          });

          if (data.length) {
            this.filters[0].options = Object.keys(obj)
              .sort((a, b) => b - a)
              .map((y) => ({
                id: y,
                label: y,
              }));
            this.year = this.filters[0].options[0].id;

            this.regions = Object.keys(obj[this.year]).filter((e) => e !== 'PK');
          }

          return obj;
        },
        getData(data) {
          return this.regions
            .map((c) => {
              try {
                return data[this.year][c][this.pol][this.payment][this.disability_group][this.age];
              } catch (e) {
                return [];
              }
            })
            .sort((a, b) => (a - b) * (this.sort_type === 'inc' ? 1 : -1));
        },
        getCategories(data) {
          const v = data[this.year];
          return this.regions.sort((a, b) => {
            try {
              const aV = v[a] ? v[a][this.pol][this.payment][this.disability_group][this.age] : 0;
              const bV = v[b] ? v[b][this.pol][this.payment][this.disability_group][this.age] : 0;
              return (aV - bV) * (this.sort_type === 'inc' ? 1 : -1);
            } catch (e) {
              return 0;
            }
          });
        },
        parseFunc(data) {
          const seriesData = this.getData(data);
          const categories = this.getCategories(data);

          return {
            credits: false,
            chart: {
              type: this.chart,
            },
            title: {
              text: '',
            },
            xAxis: {
              categories: categories,
              labels: {
                useHTML: true,
                style: {
                  width: '150px',
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                },
                step: 1,
                formatter: function () {
                  return (
                    '<div align="center" style="width:150px;text-transform:capitalize;overflow:hidden;">' +
                    this.value +
                    '</div>'
                  );
                },
              },
            },
            yAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                formatter: (a) => {
                  if (a.value / 1000 > 0) return `${a.value / 1000} ${$t['tg_1000']}`;
                  return `${a.value / 1000}`
                },
                rotation: -90,
              },
              scrollbar: {
                enabled: true,
              },
              accessibility: {
                rangeDescription: 'Range: 0 to 100000',
              },
              title: {
                text: '',
              },
            },
            scrollbar: {
              enabled: true,
            },
            legend: {
              enabled: false,
            },
            tooltip: {
              backgroundColor: null,
              borderWidth: 0,
              shadow: false,
              useHTML: true,
              formatter: function () {
                return (
                  '<div style="width: 200px; cursor: pointer; white-space: wrap; font-weight: 400; font-size: 12px;"><div title="' +
                  this.x +
                  '">' +
                  this.x +
                  ' - ' +
                  Math.abs(this.y) +
                  '</div><div/>'
                );
              },
              style: {
                fontSize: '12px',
              },
              valueSuffix: '',
            },
            series: [
              {
                name: '',
                color: '#FFA149',
                data: seriesData,
              },
            ],
            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {},
                },
              ],
            },
          };
        },
        updateFunc(data, filter, option) {
          this[filter.label] = option.id;
          const seriesData = this.getData(data);

          return {
            xAxis: {
              categories: this.getCategories(data),
              labels: {
                useHTML: true,
                style: {
                  width: '150px',
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                },
                step: 1,
                formatter: function () {
                  return (
                    '<div align="center" style="width:150px;text-transform:capitalize;overflow:hidden;">' +
                    this.value +
                    '</div>'
                  );
                },
              },
            },
            tooltip: {
              backgroundColor: null,
              borderWidth: 0,
              shadow: false,
              useHTML: true,
              formatter: function () {
                return (
                  '<div style="width: 200px; cursor: pointer; white-space: wrap; font-weight: 400; font-size: 12px;"><div title="' +
                  this.x +
                  '">' +
                  this.x +
                  ' - ' +
                  Math.abs(this.y) +
                  '</div><div/>'
                );
              },
              style: {
                fontSize: '12px',
              },
              valueSuffix: '',
            },
            series: [
              {
                name: '',
                data: seriesData,
              },
            ],
          };
        },
      },
      {
        type: 'social_security7',
        api: '/integrate/social/welfare?type=social_welfare_main',
        chart: 'bar',
        info: 'social_security_info_7',
        hideMain: true,
        year: '2023',
        pol: 2,
        sort_type: 'inc',
        payment: 'Социальные выплаты из ГФСС',
        index: 'srp',
        filters: [
          {
            type: 'dropdown',
            label: 'year',
            options: [],
          },
          {
            type: 'dropdown',
            label: 'pol',
            options: [
              { id: 2, label: 'basic' },
              { id: 1, label: 'male' },
              { id: 0, label: 'female' },
            ],
          },
          {
            type: 'dropdown',
            label: 'index',
            options: [
              { id: 'srp', label: 'srp' },
              { id: 'total_count', label: 'count' },
            ],
          },
          {
            type: 'dropdown',
            label: 'sort_type',
            options: [
              { id: 'inc', label: 'inc' },
              { id: 'dec', label: 'dec' },
            ],
          },
        ],
        regions: [],
        normalize(data) {
          const obj = {};

          data.forEach((e) => {
            if (!e.year || (e.payment_name !== this.payment)) return;

            if (!obj[e.year]) obj[e.year] = {};
            if (!obj[e.year][e.reg_ru]) obj[e.year][e.reg_ru] = {};
            const pol = e.pol ? e.pol?.id : 0
            if (!obj[e.year][e.reg_ru][pol]) obj[e.year][e.reg_ru][pol] = {};
            if (!obj[e.year][e.reg_ru][pol][e.payment_name]) obj[e.year][e.reg_ru][pol][e.payment_name] = e
          });

          if (data.length) {
            this.filters[0].options = Object.keys(obj)
              .sort((a, b) => b - a)
              .map((y) => ({
                id: y,
                label: y,
              }));
            this.year = this.filters[0].options[0].id;

            this.regions = Object.keys(obj[this.year]).filter((e) => e !== 'PK');
          }

          return obj;
        },
        getData(data) {
          return this.regions
            .map((c) => {
              try {
                return data[this.year][c][this.pol][this.payment][this.index];
              } catch (e) {
                return [];
              }
            })
            .sort((a, b) => (a - b) * (this.sort_type === 'inc' ? 1 : -1));
        },
        getCategories(data) {
          const v = data[this.year];
          return this.regions.sort((a, b) => {
            try {
              const aV = v[a] ? v[a][this.pol][this.payment][this.index] : 0;
              const bV = v[b] ? v[b][this.pol][this.payment][this.index] : 0;
              return (aV - bV) * (this.sort_type === 'inc' ? 1 : -1);
            } catch (e) {
              return 0;
            }
          });
        },
        parseFunc(data) {
          const seriesData = this.getData(data);
          const categories = this.getCategories(data);

          return {
            credits: false,
            chart: {
              type: this.chart,
            },
            title: {
              text: '',
            },
            xAxis: {
              categories: categories,
              labels: {
                useHTML: true,
                style: {
                  width: '150px',
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                },
                step: 1,
                formatter: function () {
                  return (
                    '<div align="center" style="width:150px;text-transform:capitalize;overflow:hidden;">' +
                    this.value +
                    '</div>'
                  );
                },
              },
            },
            yAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                formatter: (a) => {
                  if (a.value / 1000 > 0) return `${a.value / 1000} ${$t['tg_1000']}`;
                  return `${a.value / 1000}`
                },
                rotation: -90,
              },
              scrollbar: {
                enabled: true,
              },
              accessibility: {
                rangeDescription: 'Range: 0 to 100000',
              },
              title: {
                text: '',
              },
            },
            scrollbar: {
              enabled: true,
            },
            legend: {
              enabled: false,
            },
            tooltip: {
              backgroundColor: null,
              borderWidth: 0,
              shadow: false,
              useHTML: true,
              formatter: function () {
                return (
                  '<div style="width: 200px; cursor: pointer; white-space: wrap; font-weight: 400; font-size: 12px;"><div title="' +
                  this.x +
                  '">' +
                  this.x +
                  ' - ' +
                  Math.abs(this.y) +
                  '</div><div/>'
                );
              },
              style: {
                fontSize: '12px',
              },
              valueSuffix: '',
            },
            series: [
              {
                name: '',
                color: '#FFA149',
                data: seriesData,
              },
            ],
            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {},
                },
              ],
            },
          };
        },
        updateFunc(data, filter, option) {
          this[filter.label] = option.id;
          const seriesData = this.getData(data);

          return {
            xAxis: {
              categories: this.getCategories(data),
              labels: {
                useHTML: true,
                style: {
                  width: '150px',
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                },
                step: 1,
                formatter: function () {
                  return (
                    '<div align="center" style="width:150px;text-transform:capitalize;overflow:hidden;">' +
                    this.value +
                    '</div>'
                  );
                },
              },
            },
            tooltip: {
              backgroundColor: null,
              borderWidth: 0,
              shadow: false,
              useHTML: true,
              formatter: function () {
                return (
                  '<div style="width: 200px; cursor: pointer; white-space: wrap; font-weight: 400; font-size: 12px;"><div title="' +
                  this.x +
                  '">' +
                  this.x +
                  ' - ' +
                  Math.abs(this.y) +
                  '</div><div/>'
                );
              },
              style: {
                fontSize: '12px',
              },
              valueSuffix: '',
            },
            series: [
              {
                name: '',
                data: seriesData,
              },
            ],
          };
        },
      },
    ],
  },
  {
    type: 'projectionsDemographics',
    subtypes: [
      {
        type: 'projectionsDemographics10',
        mainTitle: 'projectionsDemographicsMainTitle10',
        api: '/integrate/for/site?type=for_site_content_method_one',
        chart: 'column',
        info: 'projectionsDemographics_info_10',
        region: '71',
        place: 'total',
        pol: 'total',
        years: [],
        categories: [
          { id: 'старше трудоспособного', value: $t['ableToWorkOlder'], color: '#DF6F6F' },
          { id: 'трудоспособного', value: $t['ableToWork'], color: '#686FF3' },
          { id: 'младше трудоспособного', value: $t['ableToWorkYounger'], color: '#F98500' },
        ],
        filters: [
          {
            type: 'dropdown',
            label: 'region',
            options: [],
          },
          {
            type: 'dropdown',
            label: 'place',
            options: [
              { id: 'total', label: 'all' },
              { id: 'город', label: 'city' },
              { id: 'село', label: 'township' },
            ],
          },
          {
            type: 'dropdown',
            label: 'pol',
            options: [
              { id: 'total', label: 'all' },
              { id: 'мужчины', label: 'male' },
              { id: 'женщины', label: 'female' },
            ],
          },
        ],
        normalize(data) {
          const obj = {};

          data.forEach((e) => {
            if (!e.year) return;

            if (!obj[e.year]) obj[e.year] = {};

            if (!obj[e.year][e.region.city_id]) {
              obj[e.year][e.region.city_id] = {
                name: e.region.name,
              };
            }
            if (!obj[e.year][21]) {
              obj[e.year][21] = {
                name: $t['РК'] || 'РК',
              };
            }

            if (!obj[e.year][e.region.city_id][e.area]) {
              obj[e.year][e.region.city_id][e.area] = {};
            }
            if (!obj[e.year][21][e.area]) {
              obj[e.year][21][e.area] = {};
            }

            if (!obj[e.year][e.region.city_id][e.area][e.pol]) {
              obj[e.year][e.region.city_id][e.area][e.pol] = {};
            }
            if (!obj[e.year][21][e.area][e.pol]) {
              obj[e.year][21][e.area][e.pol] = {};
            }

            if (!obj[e.year][21][e.area][e.pol][e.age_group]) {
              obj[e.year][21][e.area][e.pol][e.age_group] = e.population;
            } else {
              obj[e.year][21][e.area][e.pol][e.age_group] = obj[e.year][21][e.area][e.pol][e.age_group] + e.population;
            }
            if (!obj[e.year][e.region.city_id][e.area][e.pol][e.age_group]) {
              obj[e.year][e.region.city_id][e.area][e.pol][e.age_group] = e.population;
            }
          });

          if (data.length) {
            this.years = Object.keys(obj).sort((a, b) => a - b);

            const firstYear = this.years[0];

            this.filters[0].options = Object.keys(obj[firstYear]).map((r) => {
              return {
                id: r,
                label: obj[firstYear][r].name,
              };
            }).sort((a, b) => obj['2022'][b.id]['город']['мужчины']['старше трудоспособного'] - obj['2022'][a.id]['город']['мужчины']['старше трудоспособного']);

            this.region = '21';
          }

          return obj;
        },
        getSeries(data, years) {
          return this.categories.map((category) => {
            let comData
            if (this.pol === 'total') {
              if (this.place === 'total') {
                comData = this.years.map((y) => data[y][this.region]['город']['мужчины'][category.id] + data[y][this.region]['город']['женщины'][category.id] + (data[y][this.region]['село'] || data[y][this.region]['город'])['мужчины'][category.id] + (data[y][this.region]['село'] || data[y][this.region]['город'])['женщины'][category.id])
              } else {
                comData = years.map((y) => data[y][this.region][this.place || 'город']['мужчины'][category.id] + data[y][this.region][this.place || 'город']['женщины'][category.id])
              }
            } else {
              if (this.place === 'total') {
                comData = years.map((y) => data[y][this.region]['город'][this.pol][category.id] + (data[y][this.region]['село'] || data[y][this.region]['город'])[this.pol][category.id])
              } else {
                comData = years.map((y) => data[y][this.region][this.place || 'город'][this.pol][category.id])
              }
            }

            return {
              name: category.value,
              color: category.color,
              data: comData,
              stack: 'year',
            };
          });
        },
        parseFunc(data) {
          return {
            credits: false,
            chart: {
              type: this.chart,
            },
            title: {
              text: '',
            },
            xAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                rotation: -90,
              },
              categories: this.years,
            },
            yAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                formatter: (a) => {
                  if (a.value > 999999) return `${a.value / 1000000} ${$t['per_1mln']}`;
                  if (Math.round(a.value / 1000) > 0) return `${a.value / 1000} ${$t['per_1000']}`;
                  return `${a.value / 1000}`;
                },
              },
              allowDecimals: true,
              min: 0,
              title: {
                text: '',
              },
            },
            scrollbar: {
              enabled: true,
            },
            legend: {
              itemStyle: {
                font: '12px Trebuchet MS, Verdana, sans-serif',
              },
              enabled: true,
            },
            tooltip: {
              backgroundColor: null,
              borderWidth: 0,
              shadow: false,
              useHTML: true,
              formatter: function () {
                return (
                  '<div style="width: 140px; background: #fff;cursor: pointer; white-space: wrap; font-weight: 400; font-size: 14px;"><div title="' +
                  this.x +
                  '">' +
                  this.x +
                  '</div><div><span>' +
                  $t['person_short'] +
                  ' : </span>' +
                  Math.abs(this.y) +
                  '</div></div>'
                );
              },
            },
            plotOptions: {
              column: {
                stacking: 'normal',
              },
            },
            series: this.getSeries(data, this.years),
          };
        },
        updateFunc(data, filter, option) {
          this[filter.label] = option.id;

          return {
            series: this.getSeries(data, this.years),
          };
        },
      },
      {
        type: 'projectionsDemographics11',
        mainTitle: 'projectionsDemographicsMainTitle11',
        api: '/integrate/for/site?type=for_site_content_method_two',
        chart: 'line',
        info: 'projectionsDemographics_info_11',
        region: '71',
        place: 'total',
        pol: 'total',
        categoriesColors: ['#F98500', '#DF6F6F', '#686FF3', '#F98500'],
        years: [],
        categories: [],
        filters: [
          {
            type: 'dropdown',
            label: 'region',
            options: [],
          },
          {
            type: 'dropdown',
            label: 'place',
            options: [
              { id: 'total', label: 'all' },
              { id: 'город', label: 'city' },
              { id: 'село', label: 'township' },
            ],
          },
          {
            type: 'dropdown',
            label: 'pol',
            options: [
              { id: 'total', label: 'all' },
              { id: 'мужчины', label: 'male' },
              { id: 'женщины', label: 'female' },
            ],
          },
        ],
        getSeries(data) {
          return this.categories.map((category) => {
            let comData
            if (this.pol === 'total') {
              if (this.place === 'total') {
                comData = this.years.map((y) => data[y][this.region]['мужчины']['город'][category.id] + data[y][this.region]['женщины']['город'][category.id] + (data[y][this.region]['мужчины']['село'] || data[y][this.region]['мужчины']['город'])[category.id] + (data[y][this.region]['женщины']['село'] || data[y][this.region]['женщины']['город'])[category.id])
              } else {
                comData = this.years.map((y) => data[y][this.region]['мужчины'][this.place || 'город'][category.id] + data[y][this.region]['женщины'][this.place || 'город'][category.id])
              }
            } else {
              if (this.place === 'total') {
                comData = this.years.map((y) => data[y][this.region][this.pol]['город'][category.id] + (data[y][this.region][this.pol]['село'] || data[y][this.region][this.pol]['город'])[category.id])
              } else {
                comData = this.years.map((y) => data[y][this.region][this.pol][this.place || 'город'][category.id])
              }
            }

            return {
              name: $t[category.id],
              color: category.color,
              data: comData,
            };
          });
        },
        normalize(data) {
          const obj = {};

          data.forEach((e) => {
            if (!e.year) return;

            if (!obj[e.year]) obj[e.year] = {};

            if (!obj[e.year][21]) {
              obj[e.year][21] = {
                name: $t['РК'] || 'РК',
              };
            }
            if (!obj[e.year][e.region.city_id]) {
              obj[e.year][e.region.city_id] = {
                name: e.region.name,
              };
            }

            if (!obj[e.year][21][e.pol]) {
              obj[e.year][21][e.pol] = {};
            }
            if (!obj[e.year][e.region.city_id][e.pol]) {
              obj[e.year][e.region.city_id][e.pol] = {};
            }

            if (!obj[e.year][21][e.pol][e.area]) {
              obj[e.year][21][e.pol][e.area] = {};
            }
            if (!obj[e.year][e.region.city_id][e.pol][e.area]) {
              obj[e.year][e.region.city_id][e.pol][e.area] = {};
            }

            if (!obj[e.year][21][e.pol][e.area][e.script]) {
              obj[e.year][21][e.pol][e.area][e.script] = e.population;
            } else {
              obj[e.year][21][e.pol][e.area][e.script] = obj[e.year][21][e.pol][e.area][e.script] + e.population;
            }
            if (!obj[e.year][e.region.city_id][e.pol][e.area][e.script]) {
              obj[e.year][e.region.city_id][e.pol][e.area][e.script] = e.population;
            }
          });

          if (data.length) {
            this.years = Object.keys(obj).sort((a, b) => a - b);

            const firstYear = this.years[0];

            this.filters[0].options = Object.keys(obj[firstYear]).map((r) => {
              return {
                id: r,
                label: obj[firstYear][r].name,
              };
            }).sort((a, b) => obj['2022'][b.id]['женщины']['город']['базовый'] - obj['2022'][a.id]['женщины']['город']['базовый']);

            this.region = '21';

            this.categories = Object.keys(obj[firstYear][this.region]['мужчины']['город']).map(
              (i, ind) => {
                return {
                  id: i,
                  value: $t[i],
                  color: this.categoriesColors[ind],
                };
              }
            );
          }

          return obj;
        },
        parseFunc(data) {
          return {
            credits: false,
            chart: {
              type: this.chart,
            },
            title: {
              text: '',
            },
            yAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                formatter: (a) => {
                  if (a.value > 999999) return `${a.value / 1000000} ${$t['per_1mln']}`;
                  if (Math.round(a.value / 1000) > 0) return `${a.value / 1000} ${$t['per_1000']}`;
                  return `${a.value / 1000}`;
                },
              },
              allowDecimals: true,
              min: 0,
              title: {
                text: '',
              },
            },
            xAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                rotation: -90,
              },
              categories: this.years,
            },
            scrollbar: {
              enabled: true,
            },
            legend: {
              itemStyle: {
                fontSize: '12px',
              },
            },
            series: this.getSeries(data),
            tooltip: {
              backgroundColor: null,
              borderWidth: 0,
              shadow: false,
              useHTML: true,
              formatter: function () {
                return (
                  '<div style="width: 240px; background: #fff;cursor: pointer; white-space: wrap; font-weight: 400; font-size: 14px;"><div title="' +
                  this.x +
                  '">' +
                  this.x +
                  '</div><div><span style="display: inline-block;width: 8px;height: 8px;border-radius: 50%;margin-right:10px;background: ' +
                  this.series.color +
                  ';"></span><span>' +
                  this.series.name +
                  ' : </span>' +
                  Math.abs(this.y) +
                  '</div></div>'
                );
              },
            },
          };
        },
        updateFunc(data, filter, option) {
          this[filter.label] = option.id;

          return {
            series: this.getSeries(data),
          };
        },
      },
      {
        type: 'projectionsDemographics12',
        mainTitle: 'projectionsDemographicsMainTitle12',
        api: '/integrate/for/site?type=for_site_content_method_three',
        chart: 'pie',
        info: 'projectionsDemographics_info_12',
        year: 2030,
        region: '71',
        categoriesColors: ['#F98500', '#686FF3', '#DF6F6F'],
        place: [
          { id: 'город', label: $t['city'] },
          { id: 'село', label: $t['township'] },
        ],
        filters: [
          {
            type: 'dropdown',
            label: 'year',
            options: [],
          },
          {
            type: 'dropdown',
            label: 'region',
            options: [],
          },
        ],
        getData(data) {
          return this.place.map((r, rInd) => {
            const d = data[this.year][this.region][r.id];
            return {
              name: r.label,
              color: this.categoriesColors[rInd],
              y: d,
            };
          });
        },
        normalize(data) {
          const obj = {};

          data.forEach((e) => {
            if (!e.year) return;

            if (!obj[e.year]) obj[e.year] = {};

            if (!obj[e.year][21]) {
              obj[e.year][21] = {
                name: $t['РК'] || 'РК',
              };
            }
            if (!obj[e.year][e.region.city_id]) {
              obj[e.year][e.region.city_id] = {
                name: e.region.name,
              };
            }

            if (!obj[e.year][21][e.area]) {
              obj[e.year][21][e.area] = e.population;
            } else {
              obj[e.year][21][e.area] = obj[e.year][21][e.area] + e.population;
            }
            if (!obj[e.year][e.region.city_id][e.area]) {
              obj[e.year][e.region.city_id][e.area] = e.population;
            }
          });

          if (data.length) {
            this.filters[0].options = Object.keys(obj)
              .sort((a, b) => a - b)
              .map((y) => {
                return {
                  id: y,
                  label: y,
                };
              });

            this.year = this.filters[0].options.find((c) => c.id == 2030)?.id

            this.filters[1].options = Object.keys(obj[this.year]).map((region, ind) => {
              return {
                id: region,
                label: obj[this.year][region].name,
              };
            }).sort((a, b) => obj[this.year][b.id]['город'] - obj[this.year][a.id]['город']);

            this.region = '21';
          }

          return obj;
        },
        parseFunc(data) {
          return {
            credits: false,
            chart: {
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              type: this.chart,
            },
            title: {
              text: '',
              align: 'left',
            },
            tooltip: {
              style: {
                fontSize: '14px',
                fontWeight: 400,
              },
              pointFormat: '{point.percentage:.1f}%</b>',
            },
            accessibility: {
              point: {
                // valueSuffix: '%'
              },
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format:
                    '<span style="font-size: 1.2em"><b>{point.name}</b></span><br>' +
                    '<span style="opacity: 0.6">{point.percentage:.1f} %</span>',
                  connectorColor: 'rgba(128,128,128,0.5)',
                  style: {
                    color: '#626A77',
                    fontWeight: '400',
                    fontSize: '12px',
                  },
                },
              },
            },
            series: [
              {
                name: $t['population'],
                label: {
                  style: {
                    color: '#626A77',
                    fontWeight: '400',
                    fontSize: '12px',
                  },
                },
                data: this.getData(data),
              },
            ],
          };
        },
        updateFunc(data, filter, option) {
          this[filter.label] = option.id;

          return {
            series: [
              {
                name: $t['population'],
                data: this.getData(data),
              },
            ],
          };
        },
      },
      {
        type: 'projectionsDemographics13',
        mainTitle: 'projectionsDemographicsMainTitle13',
        api: '/integrate/for/site?type=for_site_content_method_four',
        chart: 'column',
        info: 'projectionsDemographics_info_13',
        region: '71',
        place: 'total',
        scenario: 'базовый',
        filters: [
          {
            type: 'dropdown',
            label: 'region',
            options: [],
          },
          {
            type: 'dropdown',
            label: 'place',
            options: [
              { id: 'total', label: 'all' },
              { id: 'город', label: 'city' },
              { id: 'село', label: 'township' },
            ],
          },
          {
            type: 'dropdown',
            label: 'scenario',
            options: [
              { id: 'базовый', label: 'base' },
              { id: 'оптимистический', label: 'optimistic' },
              { id: 'пессимистический', label: 'pesimistic' },
            ],
          },
        ],
        years: [],
        categories: [
          { id: 'birth', color: '#686FF3' },
          { id: 'death', color: '#DF6F6F' },
        ],
        normalize(data) {
          const obj = {};

          data.forEach((e) => {
            if (!e.year) return;

            if (!obj[e.year]) obj[e.year] = {};

            if (!obj[e.year][21])
              obj[e.year][21] = {
                name: $t['РК'] || 'РК',
              };
            if (!obj[e.year][e.region.city_id])
              obj[e.year][e.region.city_id] = {
                name: e.region.name,
              };

            if (!obj[e.year][21][e.area]) obj[e.year][21][e.area] = {};
            if (!obj[e.year][e.region.city_id][e.area]) obj[e.year][e.region.city_id][e.area] = {};

            if (!obj[e.year][21][e.area][e.script]) {
              obj[e.year][21][e.area][e.script] = {
                birth: e.birth,
                death: e.death,
              };
            }
            else {
              obj[e.year][21][e.area][e.script].birth = obj[e.year][21][e.area][e.script].birth + e.birth
              obj[e.year][21][e.area][e.script].death = obj[e.year][21][e.area][e.script].death + e.death
            }

            if (!obj[e.year][e.region.city_id][e.area][e.script])
              obj[e.year][e.region.city_id][e.area][e.script] = {
                birth: e.birth,
                death: e.death,
              };
          });

          if (data.length) {
            this.years = Object.keys(obj);

            const yearData = obj[this.years[0]];

            this.filters[0].options = Object.keys(yearData).map((k) => {
              return {
                id: k,
                label: yearData[k].name,
              };
            }).sort((a, b) => obj['2023'][b.id]['город']['базовый'].birth - obj['2023'][a.id]['город']['базовый'].birth);

            this.region = '21'
          }

          return obj;
        },
        getSeries(data, years) {
          return this.categories.map((c) => {
            let comData
            if (this.place === 'total') {
              comData = years.map((y) => +data[y][this.region]['город'][this.scenario][c.id] + +(data[y][this.region]['село'] || data[y][this.region]['город'])[this.scenario][c.id])
            } else {
              comData = years.map((y) => +data[y][this.region][this.place || 'город'][this.scenario][c.id])
            }
            return {
              name: $t[c.id] || c.id,
              pol: $t[c.id],
              color: c.color,
              data: comData,
              stack: $t[c.id],
            };
          });
        },
        parseFunc(data) {
          return {
            credits: false,
            chart: {
              type: 'column',
            },
            title: {
              text: '',
            },
            xAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                rotation: -90,
              },
              categories: this.years,
            },
            yAxis: {
              labels: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
                formatter: (a) => {
                  if (a.value > 999999) return `${a.value / 1000000} ${$t['per_1mln']}`;
                  if (Math.round(a.value / 1000) > 0) return `${a.value / 1000} ${$t['per_1000']}`;
                  return `${a.value / 1000}`;
                },
              },
              allowDecimals: true,
              min: 0,
              title: {
                text: '',
              },
            },
            scrollbar: {
              enabled: true,
            },
            legend: {
              itemStyle: {
                font: '12px Trebuchet MS, Verdana, sans-serif',
              },
              enabled: true,
            },
            tooltip: {
              style: {
                fontSize: '14px',
                fontWeight: 400,
              },
              valueDecimals: 3,
              formatter: function () {
                return `${this.x}<br/>${this.series.name}: ${this.y}`;
              },
            },
            plotOptions: {
              column: {
                stacking: 'stream',
              },
            },
            series: this.getSeries(data, this.years),
          };
        },
        updateFunc(data, filter, option) {
          this[filter.label] = option.id;

          return {
            series: this.getSeries(data, this.years),
          };
        },
      },
    ],
  },
];

export const bannerData = [
  {
    type: 'employment',
    subtypes: [
      {
        type: '',
        api: '',
        info: '',
        normalize(data) {
          return data;
        },
        mockDAta: {
          credits: false,
          chart: {
            backgroundColor: 'transparent',
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
          },
          title: {
            text: $t['populationStructureAge18'] || 'populationStructureAge18',
            style: {
              color: '#FFFFFF',
              fontWeight: '600',
              fontSize: '18px',
            },
          },
          accessibility: {
            point: {},
          },
          tooltip: {
            style: {
              fontSize: '14px',
              fontWeight: 400,
            },
            pointFormat: '{point.percentage:.1f}%</b>',
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: true,
                format:
                  '<span style="font-size: 1.2em; color: #fff;"><b>{point.name}</b></span><br>' +
                  '<span style="opacity: 0.6; color: #fff;">{point.percentage:.1f} %</span>',
                style: {
                  color: '#fff',
                  fontWeight: '400',
                  fontSize: '12px',
                },
              },
            },
          },
          series: [
            {
              name: $t['population'],
              label: {
                style: {
                  color: '#626A77',
                  fontWeight: '400',
                  fontSize: '12px',
                },
              },
              data: [
                {
                  name: $t['populationStructures']['salaried'],
                  color: '#EA8B32',
                  y: 6481508,
                },
                {
                  name: $t['populationStructures']['children'],
                  color: '#F9C74F',
                  y: 6694244,
                },
                {
                  name: $t['populationStructures']['pensioners'],
                  color: '#90BE6D',
                  y: 2391287,
                },
                {
                  name: $t['populationStructures']['individual'],
                  color: '#4C7DF9',
                  y: 1614164,
                },
                {
                  name: $t['populationStructures']['students'],
                  color: '#FF6A6A',
                  y: 1187298,
                },
                {
                  name: $t['populationStructures']['disability'],
                  color: '#FA5098',
                  y: 720283,
                },
              ],
            },
          ],
          responsive: {
            rules: [
              {
                condition: {
                  maxWidth: 500,
                },
                chartOptions: {},
              },
            ],
          },
        },
      },
    ],
  },
  {
    type: 'income',
    subtypes: [
      {
        type: '',
        api: '',
        chart: 'bar',
        info: '',
        normalize(data) {
          return data;
        },
        mockDAta: {
          credits: false,
          chart: {
            type: 'bar',
            backgroundColor: 'transparent',
          },
          title: {
            text: $t['averageRegionSalary'] || 'averageRegionSalary',
            style: {
              color: '#FFFFFF',
              fontWeight: '600',
              fontSize: '18px',
            },
          },
          xAxis: {
            categories: [$t['regions']['megacities'], $t['regions']['west'], $t['regions']['north'], $t['regions']['east'], $t['regions']['south'], $t['regions']['center']],
            labels: {
              useHTML: true,
              style: {
                width: '80px',
                color: '#FFFFFF',
                fontWeight: '400',
                fontSize: '12px',
                textTransform: 'capitalize',
              },
              step: 1,
              formatter: function () {
                return (
                  '<div align="center" style="width:80px;text-transform:capitalize;overflow:hidden;">' +
                  this.value +
                  '</div>'
                );
              },
            },
          },
          yAxis: {
            labels: {
              style: {
                color: '#FFFFFF',
                fontWeight: '400',
                fontSize: '12px',
              },
              formatter: (a) => {
                if (Math.abs(a.value) / 1000 > 0) return `${Math.abs(a.value) / 1000} ${$t['tg_1000']}`;
                return `${Math.abs(a.value) / 1000}`
              },
              rotation: -90,
            },
            scrollbar: {
              enabled: true,
            },
            accessibility: {
              rangeDescription: 'Range: 0 to 100000',
            },
            title: {
              text: '',
            },
          },
          scrollbar: {
            enabled: true,
          },
          legend: {
            enabled: false,
          },
          tooltip: {
            backgroundColor: null,
            borderWidth: 0,
            shadow: false,
            useHTML: true,
            formatter: function () {
              return (
                '<div style="width: 200px; cursor: pointer; white-space: wrap; font-weight: 400; font-size: 12px;"><div title="' +
                this.x +
                '">' +
                this.x +
                ' - ' +
                Math.abs(this.y) +
                '</div><div/>'
              );
            },
            style: {
              fontSize: '12px',
            },
            valueSuffix: '',
          },
          series: [
            {
              name: '',
              color: '#FFA149',
              data: [
                296586, // мегаполисы
                350089, // запад
                239222, // север
                253261, // восток
                216734, // юг
                351389 // центр
              ],
            },
          ],
          responsive: {
            rules: [
              {
                condition: {
                  maxWidth: 500,
                },
                chartOptions: {},
              },
            ],
          },
        },
      },
    ],
  },
  {
    type: 'social_security',
    subtypes: [
      {
        type: '',
        api: '',
        chart: 'bar',
        info: '',
        normalize(data) {
          return data;
        },
        mockDAta: {
          credits: false,
          chart: {
            type: 'bar',
            backgroundColor: 'transparent',
          },
          title: {
            text: $t['numberOfPensionBenefit'] || 'numberOfPensionBenefit',
            style: {
              color: '#FFFFFF',
              fontWeight: '600',
              fontSize: '18px',
            },
          },
          xAxis: {
            categories: [$t['regions']['megacities'], $t['regions']['west'], $t['regions']['north'], $t['regions']['east'], $t['regions']['south'], $t['regions']['center']],
            labels: {
              useHTML: true,
              style: {
                width: '80px',
                color: '#FFFFFF',
                fontWeight: '400',
                fontSize: '12px',
                textTransform: 'capitalize',
              },
              step: 1,
              formatter: function () {
                return (
                  '<div align="center" style="width:80px;text-transform:capitalize;overflow:hidden;">' +
                  this.value +
                  '</div>'
                );
              },
            },
          },
          yAxis: {
            labels: {
              style: {
                color: '#FFFFFF',
                fontWeight: '400',
                fontSize: '12px',
              },
              formatter: (a) => {
                if (Math.abs(a.value) / 1000 > 0) return `${Math.abs(a.value) / 1000} ${$t['tg_1000']}`;
                return `${Math.abs(a.value) / 1000}`
              },
              rotation: -90,
            },
            scrollbar: {
              enabled: true,
            },
            accessibility: {
              rangeDescription: 'Range: 0 to 100000',
            },
            title: {
              text: '',
            },
          },
          scrollbar: {
            enabled: true,
          },
          tooltip: {
            backgroundColor: null,
            borderWidth: 0,
            shadow: false,
            useHTML: true,
            formatter: function (a) {
              return (
                '<div style="width: 200px; cursor: pointer; white-space: wrap; font-weight: 600; font-size: 12px;"><div title="' +
                this.x +
                '"> ' +
                this.x +
                ' (' +
                this.series.name +
                ') - ' +
                Math.abs(this.y) +
                '</div><div/>'
              );
            },
            style: {
              fontSize: '12px',
            },
            valueSuffix: '',
          },
          series: [
            {
              name: $t['pensioners'],
              color: '#EA8B32',
              data: [356140, 278041, 197224, 435785, 675442, 388452],
              stack: 'year',
            },
            {
              name: $t['largeFamilies'],
              color: '#90BE6D',
              data: [128406, 89044, 55004, 62044, 127442, 95500],
              stack: 'year',
            },
            {
              name: $t['pwd'],
              color: '#4C7DF9',
              data: [122151, 88911, 64235, 64211, 118162, 78414],
              stack: 'year',
            },
            {
              name: $t['childCareUp'],
              color: '#F9C74F',
              data: [53242, 37804, 29421, 34577, 68042, 31425],
              stack: 'year',
            },
          ],
          legend: {
            enableMouseTracking: false,
            itemStyle: {
              font: '12px Trebuchet MS, Verdana, sans-serif',
              color: '#FFF',
            },
            itemHoverStyle: {
              color: '#FFF',
            },
            enabled: true,
          },
          plotOptions: {
            bar: {
              stacking: 'normal',
            },
          },
          responsive: {
            rules: [
              {
                condition: {
                  maxWidth: 500,
                },
                chartOptions: {},
              },
            ],
          },
        },
      },
    ],
  },
  {
    type: 'projectionsDemographics',
    subtypes: [
      {
        type: '',
        api: '',
        info: '',
        normalize(data) {
          return data;
        },
        mockDAta: {
          credits: false,
          chart: {
            type: 'line',
            backgroundColor: 'transparent',
          },
          title: {
            text: $t['forecastHiredWorkers'] || 'forecastHiredWorkers',
            style: {
              color: '#FFFFFF',
              fontWeight: '600',
              fontSize: '18px',
            },
          },
          yAxis: {
            labels: {
              style: {
                color: '#fff',
                fontWeight: '400',
                fontSize: '12px',
              },
            },
            title: {
              text: $t['per_1000'],
              style: {
                color: '#fff',
                fontWeight: '400',
                fontSize: '10px',
              },
            },
          },
          tooltip: {
            valueSuffix: '',
            style: {
              color: '#000',
              fontSize: '12px',
            },
          },
          xAxis: {
            labels: {
              style: {
                color: '#fff',
                fontWeight: '400',
                fontSize: '12px',
              },
            },
            categories: [2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
          },
          legend: {
            verticalAlign: 'bottom',
            itemStyle: {
              color: '#fff',
              fontWeight: '400',
              fontSize: '12px',
            },
          },
          plotOptions: {
            line: {
              pointPadding: 0.2,
              borderWidth: 0,
              groupPadding: 0.35,
              dataLabels: {
                enabled: true,
                position: 'right',
                style: {
                  color: '#fff',
                  fontWeight: '400',
                  fontSize: '12px',
                },
              },
            },
          },
          series: [
            {
              name: $t['basic_case'],
              color: '#FFA149',
              data: [217, 224, 232, 232, 237, 226, 223, 241],
            },
            {
              name: $t['pessimistic_case'],
              color: '#FF6A6A',
              data: [170, 205, 213, 220, 223, 211, 208, 225],
            },
            {
              name: $t['optimistic_case'],
              color: '#6893FF',
              data: [240, 242, 251, 244, 252, 240, 239, 256],
            },
          ],
          responsive: {
            rules: [
              {
                condition: {
                  maxWidth: 500,
                },
                chartOptions: {},
              },
            ],
          },
        },
      },
    ],
  },
];

export const mockDAta = {
  chart: {
    type: 'bar',
  },
  title: {
    text: '',
  },
  xAxis: {
    categories: ['Africa', 'America', 'Asia', 'Europe', 'Oceania'],
    title: null,
    gridLineWidth: 1,
    lineWidth: 0,
  },
  yAxis: {
    min: 0,
    title: {
      text: 'Population (millions)',
      align: 'high',
    },
    labels: {
      overflow: 'justify',
    },
    gridLineWidth: 0,
  },
  plotOptions: {
    bar: {
      borderRadius: '50%',
      dataLabels: {
        enabled: true,
      },
      groupPadding: 0.1,
    },
  },
  legend: {
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'top',
    x: -40,
    y: 80,
    floating: true,
    borderWidth: 1,
    backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
    shadow: true,
  },
  credits: {
    enabled: false,
  },
  series: [
    {
      name: 'Year 1990',
      data: [631, 727, 3202, 721, 26],
    },
    {
      name: 'Year 2000',
      data: [814, 841, 3714, 726, 31],
    },
    {
      name: 'Year 2010',
      data: [1044, 944, 4170, 735, 40],
    },
    {
      name: 'Year 2018',
      data: [1276, 1007, 4561, 746, 42],
    },
  ],
};
