import Highcharts from 'highcharts'

import groupedCategories from 'highcharts-grouped-categories'

import api from './api'
import { mockDAta } from './charts-data'

groupedCategories(Highcharts)

import { $t } from './charts_locales'

export function createDropdown(opt, func, ind = 0) {
  const wrap = document.createElement('div')
  wrap.setAttribute('class', 'dropdown')
  const btn = document.createElement('button')
  btn.setAttribute('class', 'e-btn badge badge--empty')
  btn.setAttribute('type', 'button')
  btn.setAttribute('id', 'dropdownMenuButton1')
  btn.setAttribute('data-bs-toggle', 'dropdown')
  btn.setAttribute('aria-expanded', 'false')
  const label = document.createElement('div')
  label.setAttribute('class', 'dropdown__label')
  label.textContent = ($t[opt.label] || opt.label) + ':'
  btn.appendChild(label)
  const val = opt.options[ind]
  const selected = document.createElement('div')
  if (val !== undefined) selected.textContent = $t[val.label] || val.label
  btn.appendChild(selected)
  wrap.appendChild(btn)
  const div = document.createElement('div')
  div.setAttribute('class', 'dropdown-menu')
  const ul = document.createElement('ul')
  ul.setAttribute('class', 'dropdown-menu__inner')
  ul.setAttribute('aria-labelledby', 'dropdownMenuButton1')
  let selectInd = ind
  const list = opt.options.map((option, optionInd) => {
    const li = document.createElement('li')
    li.setAttribute('class', optionInd === selectInd ? 'active' : '')
    li.setAttribute('data-type', optionInd)
    li.textContent = $t[option.label] || option.label
    return li
  })
  ul.append(...list)
  ul.addEventListener('click', (e) => {
    const li = e.target.closest('li')
    if (li && li.dataset.type) {
      list[selectInd]?.classList.remove('active')
      selectInd = +li.dataset.type
      list[selectInd]?.classList.add('active')

      if (func) {
        const val = opt.options[selectInd]
        if (val !== undefined) selected.textContent = $t[val.label] || val.label
        func(val)
      }
    }
  })
  div.append(ul)
  if (func) func(opt.options[selectInd])
  wrap.appendChild(div)
  return wrap
}

export function drawGrapic(graph, data) {
  try {
    return Highcharts.chart(graph.type, graph.parseFunc ? graph.parseFunc(data) : mockDAta)
  } catch (e) {
    console.log('Highcharts error', e)
  }
}

export async function fetchData(opt) {
  try {
    if (opt.api) {
      const res = await api.get(opt.api)
      if (Array.isArray(res?.data?.data) && res?.data?.data.length) return res?.data?.data
      else if (Array.isArray(res?.data?.message) && res?.data?.message.length) return res?.data?.message
      return []
    }
  } catch (e) {
    console.log('fetchData', e)
  }
}
